import { Modal, Button, Spinner } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import React ,{ Component } from 'react';
import FullTextShowStore from '../../Stores/FullTextShowStore';
import './FullTextShowModal.css'
interface FullTextShowModalComponentProps {
    textShow?: FullTextShowStore
}

@inject('textShow')
@observer
export default class FullTextShowModalComponent extends Component<FullTextShowModalComponentProps> {

    render() {
        return <Modal
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            show={this.props.textShow.Show}
            onHide={this.onHide}
            centered
        >
            <Modal.Header>{this.props.textShow.ShowingMessage.Title}</Modal.Header>
            <Modal.Body className='text-show-modal-body'>
                {this.props.textShow.ShowingMessage.Message}
            </Modal.Body>
            <Modal.Footer>
                <Button className = 'w-50' variant = 'primary' onClick={this.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    }

    onHide = () => {
        this.props.textShow.Show=false;
        this.props.textShow.ShowingMessage.onHide();
    }
}