import * as React from 'react'
import { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import ProviderStore from '../../Providers/Stores/ProviderStore';
import DropZoneComponent from '../../Common/Components/DropZone/DropZone';
import { Redirect } from 'react-router';
import UploadHttpHelper from '../HttpHelpers/UploadHttpHelper';
import ErrorMessageStore from '../../Common/Stores/ErrorMessageStore';
import ProgressStore from '../../Progress/Stores/ProgressStore';
import LoaderStore from '../../Common/Stores/LoaderStore';
import ProviderStatus from '../../Providers/Models/ProviderStatus';
import CurrentPartnerStore from '../../CurrentPartner/Store/CurrentPartnerStore';
import ReportStore from '../../Report/Stores/ReportStore';
interface UploadComponentProps {
    routing?: RouterStore
    currentPartner?: CurrentPartnerStore
    errorMessage?: ErrorMessageStore
    progress?: ProgressStore
    loader?: LoaderStore
    report?: ReportStore
}

@inject('routing', 'currentPartner', 'errorMessage', 'progress', 'loader' , 'report')
@observer
export default class UploadComponent extends Component<UploadComponentProps> {

    fileHandler = (file: File) => {
        let acceptedTypes = ['application/x-zip-compressed', 'application/zip', 'application/octet-stream'];

        if ((file === undefined) || (acceptedTypes.findIndex(x => x == file.type) == -1)) this.props.currentPartner.setFileTypeError(true);
        else {
            this.props.currentPartner.setFileTypeError(false);            
            this.props.errorMessage.showsErrors(
                 UploadHttpHelper.SentFile(file, this.props.currentPartner.selectedPartner.providerId,
                     this.props.currentPartner.updateOnlyMetadata)
                .then(response =>  response.json())
                .then((filePreSignedUrl) => {
                 this.props.report.setFilePreSignedUrl(filePreSignedUrl);
                 this.props.routing.push('/report');
            }), this.onErrorMessageHide);
            this.props.progress.setCanUserCloseWindow(false);
            this.props.loader.showLoading("Sending file...", this.onLoaderHide);
        }
    }

    onLoaderHide = () => {
        this.props.routing.push('/progress');
    }

    onErrorMessageHide = () => {
        this.props.routing.push('/partner/upload');
    }

    // render() {
    //     return (this.props.provider.CurrentProvider == null || this.props.provider.CurrentProvider.providerStatus === ProviderStatus.Processing) ? <Redirect to='/' /> : <div >
    //         <div className='row'>
    //             <div className="w-75"><h2>{this.props.provider.CurrentProvider.providerName}</h2></div>
    //             <div className="w-25"><button style={{ float: "right" }} className="btn ripple report-page-btn m-1" onClick={() => { this.props.routing.goBack() }}>Back to providers</button></div>

    //         </div>
    //         <div>
    //             <DropZoneComponent onDrop={this.fileHandler}></DropZoneComponent>
    //         </div>
    //     </div>
    // }

    render() {
        return (
            <div><DropZoneComponent onDrop={this.fileHandler}></DropZoneComponent></div>
        )
    }
}