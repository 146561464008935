import { AppSettings } from '../../Settings/Settings';
import BaseHttpHelper from '../../Common/HttpHelpers/BaseHttpHelper';
import UserModel from '../Models/UserModel';

export default class ManageAccountHttpHelper {

    static GetUsers(): Promise<UserModel[]> {
        var user = 'User'
        var url = `${AppSettings.BaseUrl}/${user}/get-user`;
        return BaseHttpHelper.SendGetRequest<UserModel[]>(url)
    }
}