
import * as React from 'react'
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import './Providers.css'
import ProviderModel from '../Models/ProviderModel';
import ProviderStore from '../Stores/ProviderStore';
import ProviderStatus from '../Models/ProviderStatus';
import WarningMessageStore from '../../Common/Stores/WarningMessageStore';
import ProgressStore from '../../Progress/Stores/ProgressStore';
import TitledSpinner from '../../Common/Components/TitledSpinner/TitledSpinner';
import LoaderStore from '../../Common/Stores/LoaderStore';
import { SortingState } from '../../Common/Sorting/SortState';
import SortingComponent from '../../Common/Sorting/Sorting';
import CurrentPartnerStore from '../../CurrentPartner/Store/CurrentPartnerStore';
import { Table } from 'react-bootstrap';

interface ProviderComponentProps {
    routing: RouterStore
    provider: ProviderStore
    progress: ProgressStore
    loader: LoaderStore
    currentPartner: CurrentPartnerStore
}

interface ProviderComponentState {
    loadingData: boolean,
    sortingStateName: SortingState
    sortingStateDate: SortingState
}

@inject('routing', 'provider', 'progress', 'loader', 'currentPartner')
@observer
export default class ProviderComponent extends Component<ProviderComponentProps, ProviderComponentState> {
    state = {
        loadingData: true,
        sortingStateName: SortingState.NotSorted,
        sortingStateDate: SortingState.NotSorted
    }
    componentDidMount() {
        this.props.loader.hideLoading();
        this.props.provider.loadProviders().then(() => {
            this.setState({ loadingData: false })
        });
        this.props.provider.CurrentProvider = null;
    }

    HandleChangeSortName = (newState: SortingState) => {
        this.setState({
            sortingStateName: newState,
            sortingStateDate: SortingState.NotSorted
        })
        this.props.provider.Providers = this.props.provider.Providers.slice().sort((n1, n2) => {
            return newState === SortingState.Down ? (n1.providerName > n2.providerName ? 1 : -1) : (n1.providerName < n2.providerName ? 1 : -1)
        });
    }

    HandleChangeSortDate = (newState: SortingState) => {
        this.setState({
            sortingStateDate: newState,
            sortingStateName: SortingState.NotSorted
        })
        this.props.provider.Providers = this.props.provider.Providers.slice().sort((n1, n2) => {
            return +(newState === SortingState.Down ? (n1.lastUpdate.getTime() < n2.lastUpdate.getTime() ? 1 : -1) :
                (n1.lastUpdate.getTime() > n2.lastUpdate.getTime() ? 1 : -1))
        });
    }

    render() {
        return (
            <React.Fragment>
                <div>
                    <div className='row'>
                        <div className='col'><h2>Partners</h2></div>
                    </div>
                    {this.state.loadingData ? <TitledSpinner title='Loading...' outlined={false} /> :
                        <div className='row table-row table-striped table-block'>
                            {/* <div className='col-xl-8 offset-xl-1' > */}
                            <Table responsive striped hover className="journal-table">
                                <thead >
                                    <tr >
                                        <th scope='col' className="thead-no-border">Partner Name <SortingComponent Click={this.HandleChangeSortName} SortingState={this.state.sortingStateName} /></th>
                                        <th scope='col' align='center' className="thead-no-border">Last Sync <SortingComponent Click={this.HandleChangeSortDate} SortingState={this.state.sortingStateDate} /></th>
                                        <th scope='col' className="center-th thead-no-border">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.provider.Providers.map((provider) =>
                                        <ProviderItem Provider={provider} key={provider.providerId} />
                                    )}
                                </tbody>
                            </Table>
                            {/* </div> */}
                        </div>
                    }
                </div >
            </React.Fragment>
        )
    }
}

interface ProviderItemProps {
    Provider: ProviderModel
    routing?: RouterStore
    provider?: ProviderStore
    warningMessage?: WarningMessageStore
    currentPartner?: CurrentPartnerStore
}

@inject('routing', 'provider', 'warningMessage', 'currentPartner')
@observer
class ProviderItem extends Component<ProviderItemProps>{
    fromNumberToNormalDate(number: number) {
        if (number.toString().length == 1) {
            return `0${number}`
        }
        else return number;
    }

    async managePartner(partner: ProviderModel) {
        this.props.currentPartner.selectedPartner = partner;
        let count = await this.props.provider.getActiveCourseCount(partner.providerId);
        this.props.currentPartner.selectedPartner.activeCourseCount = count.numberOfCourses;
        this.props.currentPartner.setJournal(count.journal);
        this.props.routing!.replace('/partner');
    }

    render() {
        var itemTime = this.props.Provider.lastUpdate;
        return <tr>
            <td>{this.props.Provider.providerName}</td>
            {itemTime.getTime() > new Date(0).getTime() ? <td align='left'>{this.fromNumberToNormalDate(itemTime.getMonth()+1)}/{this.fromNumberToNormalDate(itemTime.getDate())}/{itemTime.getFullYear()} {this.fromNumberToNormalDate(itemTime.getHours())}:{this.fromNumberToNormalDate(itemTime.getMinutes())}</td> : <td align='left'>-</td>}
            <td align='center'>
                {/* <button
                    className='btn ripple w-100'
                    hidden={this.props.Provider.providerStatus == ProviderStatus.Free}
                    onClick={() => { this.getOnProcessingClickHandler(this.props.Provider.providerStatus)(this.props.Provider) }}
                >
                    {ProviderStatus[this.props.Provider.providerStatus]}
                </button>
                <img style={{ cursor: 'pointer' }} src='upload_icon.svg' onClick={this.onUploadClick} /> */}
                <span className="new-user-link" onClick={this.managePartner.bind(this, this.props.Provider)}>Manage</span>
            </td>
        </tr>
    }
    onUploadClick = () => {
        if (this.props.Provider.providerStatus !== ProviderStatus.Free) {
            this.props.warningMessage.showWarning('All unsynced data will be erased', 'If You upload new file, all unsynced data will be erased. Continue?', () => { }, () => {
                this.redirectToUpload(this.props.Provider)
            });
        } else {
            this.redirectToUpload(this.props.Provider);
        }
    }
    getOnProcessingClickHandler = (providerStatus: ProviderStatus) => {
        switch (providerStatus) {
            case ProviderStatus.Processing: return this.redirectToProcessing;
            case ProviderStatus.Unsynced: return this.redirectToReport;
        }
    }
    redirectToReport = (provider: ProviderModel): void => {
        this.props.provider!.CurrentProvider = provider;
        this.props.routing!.push(`/report`);
    }
    redirectToProcessing = (provider: ProviderModel): void => {
        this.props.provider!.CurrentProvider = provider;
        this.props.routing!.push(`/progress`);
    }
    redirectToUpload = (provider: ProviderModel): void => {
        this.props.provider!.CurrentProvider = provider;
        this.props.routing!.push(`/upload`);
    }
}