import { observable, action, computed } from "mobx";
import PagingModel from "../../Common/Models/PagingModel";
import CourseHttpHelper from "../../Report/HttpHelpers/CourseHttpHelper";
import ErrorWarningReportModel from "../../Report/SubElements/ErrorWarnings/Models/ErrorWarningReportModel";
import SuccessReportModel from "../../Report/SubElements/Success/Models/SuccessReportModel";
import SyncStatus from "../../Report/SubElements/Success/Models/SyncStatus";
import { AppSettings } from "../../Settings/Settings";

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
const day = ' 1, ';
const time = ' 00:00:00';
const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
};

export default class RetirementStore {
    
    @observable Success: PagingModel<SuccessReportModel> = observable({ totalPages: 0, currentPage: 0, items: [] });
    @observable ErrorWarnings: PagingModel<ErrorWarningReportModel> = observable({ totalPages: 0, currentPage: 0, items: [] });
    @observable SyncSuccess: SyncStatus = SyncStatus.NotSynced;
    @observable allRetirementsDate: Date[] = [];
    @observable selectedDateIndex: number = -1;
    @observable selectedDate: string = 'Select an Available Retirement Date';
    @observable errorFileType: boolean = false;
    @observable errorDateType: boolean = false;
    @observable syncModal: boolean = false;
    @observable isShowModal: boolean = false;
    @observable discardModal: boolean = false;
    @observable text: string[] = observable([]);

    @action initDate() {
        this.allRetirementsDate = []; 

        let currentDate = new Date();
        var quarter = Math.floor((currentDate.getMonth() + 3) / 3);
        let nextDate = new Date(currentDate.getFullYear(), (quarter * 3) - 1, 1);        

        //if difference is less than 30 days then set date to next quarter
        var diffTime = (nextDate.getTime() - currentDate.getTime());
        var daysDiff = diffTime / (1000 * 3600 * 24);
        if (daysDiff < 30) {
            nextDate.setMonth(nextDate.getMonth() + 3);
        }
        this.allRetirementsDate.push(nextDate);

        for (let i = 1; i <= 3; i++) {
            quarter = Math.floor((nextDate.getMonth() + 3) / 3);
            nextDate = new Date(nextDate.getFullYear(), nextDate.getMonth() + 3, 1);
            this.allRetirementsDate.push(nextDate);
        }
    }
    
    @action setFileTypeError(error: boolean) {
        this.errorFileType = error;
    }

    @computed get getSyncModalState() {
        return this.syncModal;
    }

    @computed get getErrorState() {
        return this.errorFileType;
    }

    @action setDateTypeError(error: boolean) {
        this.errorDateType = error;
    }

    @computed get getDateErrorState() {
        return this.errorDateType;
    }

    @computed get getText() {
        return this.text;
    }

    @action setSyncModal(state: boolean) {
        this.syncModal = state;
    }

    @action setDiscardModal(state: boolean) {
        this.discardModal = state;
    }

    @action setText(text: string[]) {
        this.text = text;
    }

    @computed get getDiscardModalState() {
        return this.discardModal;
    }

    @computed get getModalState() {
        return this.isShowModal;
    }

    @action setModal(state: boolean) {
        this.isShowModal = state;
    }

    @computed get getAllRetirementsDate() {
        return this.allRetirementsDate;
    }

    @action setSelectedDate(index: number) {
        this.selectedDateIndex = index;
        this.selectedDate = this.allRetirementsDate[this.selectedDateIndex].toLocaleString("en-US", options);
    }

    @computed get getSelectedDateIndex(){
        return this.selectedDateIndex;
    }

    @computed get getSelectedDate() {
        return this.selectedDate;
    }

    @action loadSuccess = (providerId: string, currentPage: number = 1): Promise<void | PagingModel<SuccessReportModel>> => {
        return CourseHttpHelper.GetSuccessRetirement(currentPage, AppSettings.ShowedTableEntriesCount, providerId)
            .then((res) => {
                this.Success = new PagingModel<SuccessReportModel>(
                    res.totalPages,
                    res.items.map((res) => {
                        return new SuccessReportModel(
                            res.courseID, res.courseID,
                            res.title, res.description,
                            res.duration,
                            res.languages,
                            res.date
                        )
                    }),
                    currentPage
                );
            });
    }

    @action loadWarningError = (providerId: string, currentPage: number = 1): Promise<void | PagingModel<ErrorWarningReportModel>> => {
        return CourseHttpHelper.GetWarningsRetirement(currentPage, AppSettings.ShowedTableEntriesCount, providerId)
            .then((res) => {
                this.ErrorWarnings = new PagingModel<ErrorWarningReportModel>(
                    res.totalPages,
                    res.items.map((res) => {
                        return new ErrorWarningReportModel(
                            res.courseID,
                            res.courseID,
                            res.title,
                            res.errorWarning,
                            res.messageLog,
                            res.date
                        )
                    }),
                    currentPage
                );
            });
    }
}