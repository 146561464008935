import * as React from 'react';
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ProgressBar from './ProgressBar';
import ProgressStore from '../Stores/ProgressStore';
import './ProgressBar.css';
import { Redirect } from 'react-router';
import ProviderStore from '../../Providers/Stores/ProviderStore';
import ProviderStatus from '../../Providers/Models/ProviderStatus';
import CurrentPartnerStore from '../../CurrentPartner/Store/CurrentPartnerStore';

interface ProgressComponentProps {
    progress?: ProgressStore,
    currentPartner?: CurrentPartnerStore
}

@inject('progress', 'currentPartner')
@observer
export default class ProgressComponent extends Component<ProgressComponentProps> {

    render() {
        if (this.props.currentPartner.selectedPartner != null) {
            if (this.props.currentPartner.selectedPartner.providerStatus == ProviderStatus.Unsynced) {
                return <Redirect to="/report" />;
            }
            // else if (this.props.currentPartner.selectedPartner.providerStatus == ProviderStatus.Free) {
            //     return <Redirect to="/upload" />;
            // }

            else if (this.props.currentPartner.selectedPartner.providerStatus == ProviderStatus.Free) {
                return <div className='progress-bar-container'>
                    <h2>{this.props.progress.currentProcess.Status.toString()}</h2>
                    <div className='row'>
                        <ProgressBar percent={this.props.progress.currentProcess.Percent}></ProgressBar>
                    </div>
                </div>
            }

            else if (this.props.currentPartner.selectedPartner.providerStatus == ProviderStatus.Processing) {
                return <div className='progress-bar-container'>
                    <h2>{this.props.progress.currentProcess.Status.toString()}</h2>
                    <div className='row'>
                        <ProgressBar percent={this.props.progress.currentProcess.Percent}></ProgressBar>
                    </div>
                </div>
            }
            else {
                return <Redirect to="/" />
            }
        }
        else {
            return <Redirect to='/' />
        }
    }

}