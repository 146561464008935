import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import '../../../Common/Components/DropZone/DropZone.css';
import icon from '../../../Common/Components/DropZone/upload.svg';
import RetirementStore from '../../Store/RetirementStore';
import { inject } from 'mobx-react';

interface DropZoneProps {
    onDrop: Function
    retirementStore?: RetirementStore
}

@inject('retirementStore')
export default class RetirementDropZone extends Component<DropZoneProps> {

    onDrop = (acceptedFiles: any) => {
        if (acceptedFiles) {
            this.props.onDrop(acceptedFiles[0]);
        }
    }

    render() {
        let maxSize = 5368709120;
        return (
            <div>
                <Dropzone onDrop={this.onDrop}
                    accept={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                    minSize={0}
                    maxSize={maxSize}>
                    {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => (
                        <div className={!isDragActive?'drop-zone outlined':'drop-zone outlined active'} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <img className='drag-n-drop-img' src={icon}/>
                            {!isDragActive && 'Click here or drop a file to upload!'}
                            {isDragActive && !isDragReject && 'Drop it like it\'s hot!'}
                            {isDragReject && 'File type not accepted, sorry!'}
                            {rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize && <div className='text-danger mt-2'>
                                File is too large.
                            </div>}
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }
}