import { observable, action } from 'mobx';
import SimpleMessageModalModel from '../Models/SimpleModalMessageModel';
import WarningMessageModel from '../Models/WarningMessageModel';

export default class WarningMessageStore {
    @observable WarningMessage: WarningMessageModel = observable ({
        Title: "", Message: "", onCancel: ()=>{}, onOk: ()=>{}, 
    })

    @observable Show: boolean = false;

    @action
    public showWarning = (title: string, message:string, onCancel: () => void = ()=>{}, onOk: ()=> void = ()=>{}) => {
        this.Show = true;
        this.WarningMessage = {Title: title, Message: message, onCancel: onCancel, onOk: onOk};
    }
}