import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom'
import { inject, observer } from "mobx-react";
import { Navbar, Nav, Form, Button } from "react-bootstrap";
import logo from "../../Cornerstone_Logo.png";
import "./EditUser.css";
import { RouterStore } from "mobx-react-router";
import ManageAccountStore from "../../ManageAccounts/Stores/ManageAccountStore";

interface ManageAccountProps {
    manageAccount?: ManageAccountStore,
    routing?: RouterStore
}

@inject('manageAccount', 'routing')
@observer
export default class EditUser extends Component<ManageAccountProps> {

    backToUsers = (): void => {
        this.props.routing!.replace('/manage-accounts');
        this.props.manageAccount.setAllNullOnEditUser();
    }

    editPassword() {
        this.props.manageAccount.setNewUserPassword();
    }

    editAttributes() {
        this.props.manageAccount.setNewAttribute();
    }

    onPartnerChange(e: any) {
        this.props.manageAccount.setNewPartner(e.target.value);
    }

    onEmailChange(e: any) {
        this.props.manageAccount.setNewEmail(e.target.value);
    }

    onPasswordChange(e: any) {
        this.props.manageAccount.setNewPassword(e.target.value);
    }

    onRePasswordChange(e: any) {
        this.props.manageAccount.setNewRePassword(e.target.value);
    }

    render() {
        return (
            <Fragment>
                <div className="link-new-user-block">
                    <span className="new-user-link" onClick={this.backToUsers.bind(this)}>Back to Users</span>
                </div>
                <div className='center-block upper-block'>
                    <div className='main-descr'><h2>Update Existing Account</h2></div>
                    <div>
                        <p className="layer-name"><b>Partner Name</b></p>
                        <Form.Control type="text" placeholder="Partner Name" readOnly={true} onChange={this.onPartnerChange.bind(this)} value={this.props.manageAccount.newUserAttributes.partner} />
                    </div>
                    <div>
                        <p className="layer-name"><b>Username</b></p>
                        <Form.Control type="email" placeholder="Username" onChange={this.onEmailChange.bind(this)} value={this.props.manageAccount.newUserAttributes.email} />
                    </div>
                    <div className="btn-block edit-user-btn-block">
                        <Button variant="primary" className="save-user-button" onClick={this.editAttributes.bind(this)}>Save Changes</Button>
                    </div>
                    <div className="text-error-block">
                        <label className='error-label'>{this.props.manageAccount.getErrorAttMesage}</label>
                        <label className='success-label'>{this.props.manageAccount.getSuccessAttMessage}</label>
                    </div>
                </div>
                <div className='center-block down-block'>
                    <div>
                        <p className="layer-name"><b>Password</b></p>
                        <Form.Control type="password" placeholder="Password" onChange={this.onPasswordChange.bind(this)} value={this.props.manageAccount.newUserPassword.password} />
                    </div>
                    <div>
                        <p className="layer-name"><b>Re-Type Password</b></p>
                        <Form.Control type="password" placeholder="Re-Type Password" onChange={this.onRePasswordChange.bind(this)} value={this.props.manageAccount.newUserPassword.rePassword} />
                    </div>
                    <div className="btn-block edit-user-btn-block">
                        <Button variant="primary" className="save-user-button large-btn" onClick={this.editPassword.bind(this)}>Change Password</Button>
                    </div>
                    <div className="text-error-block">
                        <label className='error-label'>{this.props.manageAccount.getErrorPassMesage}</label>
                        <label className='success-label'>{this.props.manageAccount.getSuccessPassMessage}</label>
                    </div>
                </div>
            </Fragment>
        )
    }
}