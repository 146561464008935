import * as React from 'react'
import './Pagination.css'
import { Component } from 'react';

interface PaginationState {
    currentPageIndex: number;
    disabled: boolean;
}

interface PaginationProps {
    currentPageIndex: number;
    listPages: number;
    handleChangePage: (newCurrentPage: number) => any
}

export default class PaginationWithOutPromise extends Component<PaginationProps, PaginationState> {

    constructor(props: PaginationProps) {
        super(props);
        this.state = {
            currentPageIndex: 1,
            disabled: false
        }
    }

    onChangeCurrentPage(newCurrentPage: number) {
        this.setState({ disabled: true });
        this.props.handleChangePage(newCurrentPage)
        this.setState({
            currentPageIndex: newCurrentPage,
            disabled: false
        });
    }

    calculateIndex(): number {
        let currentIndex = this.state.currentPageIndex - 1;
        if (currentIndex - 2 >= 0) {
            currentIndex = currentIndex - 2;
        }
        else {
            currentIndex = 0;
        }

        if (currentIndex + 4 >= this.props.listPages) {
            currentIndex = this.props.listPages - 5;
        }
        return currentIndex;
    }

    render() {
        let currentIndex = this.calculateIndex();

        let iterator = 0;
        return <ul id='pagination' className='pagination sticky-bottom'>
            <li className={'page-item ' + (this.state.currentPageIndex <= 1 || this.state.disabled ? 'disabled' : '')}>
                <a className='page-link' onClick={() => this.onChangeCurrentPage(1)}>
                    First
            </a>
            </li>
            <li className={'page-item ' + (this.state.currentPageIndex <= 1 || this.state.disabled ? 'disabled' : '')}>
                <a className='page-link' onClick={() => this.onChangeCurrentPage(this.state.currentPageIndex - 1)}>
                    <span aria-hidden='true'>«</span>
                </a>
            </li>
            {Array.from(Array(this.props.listPages + 1).keys()).slice(1).map((item, index) => {
                if (index >= currentIndex && iterator < 5) {
                    iterator++;
                    return <li key={item} className={'page-item ' + (this.state.disabled ? 'disabled' : (item === this.state.currentPageIndex ? 'active' : ''))}>
                        <a className='page-link' onClick={this.state.disabled ? () => { } : () => this.onChangeCurrentPage(item)}>{item}</a>
                    </li>
                }
                return null;
            })}
            <li className={'page-item ' + (this.state.currentPageIndex > this.props.listPages - 1 || this.state.disabled ? 'disabled' : '')}>
                <a className='page-link' onClick={() => this.onChangeCurrentPage(this.state.currentPageIndex + 1)}>
                    <span aria-hidden='true'>»</span>
                </a>
            </li>
            <li className={'page-item ' + (this.state.currentPageIndex > this.props.listPages - 1 || this.state.disabled ? 'disabled' : '')}>
                <a className='page-link' onClick={() => this.onChangeCurrentPage(this.props.listPages)}>
                    Last
                </a>
            </li>
        </ul >
    }
}