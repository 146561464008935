import { AppSettings } from '../../Settings/Settings';
import BaseHttpHelper from '../../Common/HttpHelpers/BaseHttpHelper';
import CurrentPartnerModel from '../Models/CurrentPartnerModel';

export default class CurrentPartnerHttpHelper {

    static GetProvider(): Promise<CurrentPartnerModel> {
        var providers = 'provider';
        var url = `${AppSettings.BaseUrl}/${providers}/getCurrent`;
        return BaseHttpHelper.SendGetRequest<CurrentPartnerModel>(url)
    }
}