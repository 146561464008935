import { Modal, Button } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import React ,{ Component } from 'react';
import ErrorMessageStore from '../../Stores/ErrorMessageStore';
import "./ErrorMessageModalComponent.css";
interface ErrorModalProps {
    errorMessage?: ErrorMessageStore
}

@inject('errorMessage')
@observer
export default class ErrorModalComponent extends Component<ErrorModalProps> {

    render() {
        return <Modal
            show={this.props.errorMessage.Show}
            onHide={this.handleOnClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {this.props.errorMessage.ErrorMessage.Title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {this.props.errorMessage.ErrorMessage.Message}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className = 'w-50' variant = 'primary' onClick={this.handleOnClose}>Got it</Button>
            </Modal.Footer>
    </Modal>
  }

  
  private handleOnClose = () => {
      this.props.errorMessage.Show = false;
      this.props.errorMessage.ErrorMessage.onHide();
  }
}