import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import './DropZone.css';
import icon from './upload.svg';

interface DropZoneProps {
    onDrop: Function
}

export default class DropZoneComponent extends Component<DropZoneProps> {

    onDrop = (acceptedFiles: any) => {
        if (acceptedFiles) {
            this.props.onDrop(acceptedFiles[0]);
        }
    }

    render() {
        let maxSize = 5368709120; // 5gb
        return (
            <div>
                <Dropzone onDrop={this.onDrop}
                    accept={['application/x-zip-compressed', 'application/zip', 'application/octet-stream']}
                    // accept='application/x-zip-compressed'
                    minSize={0}
                    maxSize={maxSize}>
                    {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => (
                        <div className={!isDragActive?'drop-zone outlined':'drop-zone outlined active'} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <img className='drag-n-drop-img' src={icon}/>
                            {!isDragActive && 'Click here or drop a file to upload!'}
                            {isDragActive && !isDragReject && 'Drop it like it\'s hot!'}
                            {isDragReject && 'File type not accepted, sorry!'}
                            {rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize && <div className='text-danger mt-2'>
                                File is too large.
                            </div>}
                        </div>
                    )}
                </Dropzone>
            </div>
        );
    }
}