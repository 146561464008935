import { observable, action } from 'mobx';
import Processing from '../Models/Processing';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr'
import { AppSettings } from '../../Settings/Settings';
import StatusProcessing from '../Models/Status';
import StatusMessageModel from '../Models/StatusMessageModel';
import SignalRMessage from '../Models/SIgnalRMessage';
import LoaderStore from '../../Common/Stores/LoaderStore';
import ProviderStore from '../../Providers/Stores/ProviderStore';
import ProviderStatus from '../../Providers/Models/ProviderStatus';
import ErrorMessageStore from '../../Common/Stores/ErrorMessageStore';
import { RouterStore } from 'mobx-react-router';
import SignalRErrorMessage from '../Models/SignalRErrorMessage';
import ReportStore from '../../Report/Stores/ReportStore';
import SyncStatus from '../../Report/SubElements/Success/Models/SyncStatus';


export default class ProgressStore {
    constructor(private loaderStore: LoaderStore, private providerStore: ProviderStore, private errorMessageStore: ErrorMessageStore, private routingStore: RouterStore, private reportStore: ReportStore) {
        this.openConnection();
    }


    @observable public currentProcess: Processing = new Processing("Loading...", 0);
    @observable public canUserCloseWindow: boolean = true;

    private readonly statuses: Array<StatusMessageModel> = [
        { Status: StatusProcessing.UploadZip, StatusTitle: 'Uploading file...' },
        { Status: StatusProcessing.UnzipArchive, StatusTitle: 'Unzipping files from archive...' },
        { Status: StatusProcessing.ParseExcelMetadata, StatusTitle: 'Parsing excel metadata...' },
        { Status: StatusProcessing.ParseExcelRetired, StatusTitle: 'Parsing excel retired...' },
        { Status: StatusProcessing.ParseExcelTopCources, StatusTitle: 'Parsing excel TopCourses...' },
    ]
    private hubConnection: HubConnection;

    @action
    public setCanUserCloseWindow = (canUserCloseWindow: boolean) => {
        if (!canUserCloseWindow) {
            window.addEventListener('beforeunload', this.canUserCloseWindowHandler);
        } else {
            this.canUserCloseWindow = true;
            window.removeEventListener('beforeunload', this.canUserCloseWindowHandler);
        }
    }

    @action
    public openConnection = () => {
        this.hubConnection = new HubConnectionBuilder().withUrl(AppSettings.SignalREndpoint).build();
        this.hubConnection.on(StatusProcessing[StatusProcessing.StartProcessing], this.onStartProcessing);
        this.statuses.forEach(statusMessage => this.hubConnection.on(StatusProcessing[statusMessage.Status], this.getStatusProcessingHandler(statusMessage.StatusTitle)));
        this.hubConnection.on(StatusProcessing[StatusProcessing.EndProcessing], this.onEndProcessing);
        this.hubConnection.on(StatusProcessing[StatusProcessing.EndWithError], this.onEndWithError);
        this.hubConnection.on(StatusProcessing[StatusProcessing.Synced], this.onSynced);

        this.hubConnection.on(StatusProcessing[StatusProcessing.StartRetired], this.onStartProcessingRetired);
        this.hubConnection.on(StatusProcessing[StatusProcessing.EndRetired], this.onEndProcessingRetired);

        this.hubConnection.start().catch(err => console.log(err));
    }

    @action
    private onSynced = (message: string) => {
        let signalRMessage = JSON.parse(message) as SignalRMessage;
        this.currentProcess = new Processing("test", signalRMessage.Percent);
        if (signalRMessage.Percent == 100) this.reportStore.SyncSuccess = SyncStatus.Synced;
        // this.providerStore.setStatus(signalRMessage.ProviderId,ProviderStatus.Free);
        // if(this.providerStore.CurrentProvider!=null) {
        //     this.reportStore.SyncSuccess = SyncStatus.Synced;
        // }
    }

    @action
    private onEndProcessing = (message: string) => {
        let signalRMessage = JSON.parse(message) as SignalRMessage;
        this.providerStore.setStatus(signalRMessage.ProviderId, ProviderStatus.Unsynced);
    }

    @action
    private onStartProcessing = (message: string) => {
        let signalRMessage = JSON.parse(message) as SignalRMessage;
        this.providerStore.setStatus(signalRMessage.ProviderId, ProviderStatus.Processing);
        this.loaderStore.hideLoading();
        this.setCanUserCloseWindow(true);
    }

    @action
    private onEndWithError = (message: string) => {
        let signalRMessage = JSON.parse(message) as SignalRErrorMessage;
        this.providerStore.setStatus(signalRMessage.ProviderId, ProviderStatus.Free);
        if (this.routingStore.location.pathname == '/progress') {
            this.errorMessageStore.showError("Something went wrong", signalRMessage.Message, () => { this.routingStore.push('/partner/upload') });
        }
    }

    @action
    private onStartProcessingRetired = (message: string) => {
        let signalRMessage = JSON.parse(message) as SignalRMessage;
        this.providerStore.setStatusRetired(signalRMessage.ProviderId, ProviderStatus.Processing);
        this.loaderStore.hideLoading();
        this.setCanUserCloseWindow(true);
    }

    @action
    private onEndProcessingRetired = (message: string) => {
        let signalRMessage = JSON.parse(message) as SignalRMessage;
        this.providerStore.setStatusRetired(signalRMessage.ProviderId, ProviderStatus.Unsynced);
    }

    private getStatusProcessingHandler = (StatusTitleDisplay: string) => {
        return action((message: string) => {
            let signalRMessage = JSON.parse(message) as SignalRMessage;
            this.providerStore.setStatus(signalRMessage.ProviderId, ProviderStatus.Processing);
            this.currentProcess = new Processing(StatusTitleDisplay, signalRMessage.Percent);
        });
    }

    private canUserCloseWindowHandler(this: Window, ev: BeforeUnloadEvent) {
        ev.returnValue = "message";
    }
}