import { observable, action } from 'mobx';
import SimpleModalMessageModel from '../Models/SimpleModalMessageModel';

export default class ErrorMessageStore {
    @observable ErrorMessage: SimpleModalMessageModel = observable ({
        Title: "", Message: "", onHide: ()=>{}
    })

    @observable Show: boolean = false;
    @action 
    public showError = (title:string,message:string,onErrorMessageHide: () => void = () => {}) => {
        if(this.Show==false) {
            this.Show = true;
            this.ErrorMessage = new SimpleModalMessageModel(title,message,onErrorMessageHide)
        }
    }
    @action
    public showsErrors = (target: Promise<any>, onErrorMessageHide: () => void | Promise<any> = () => {} ) => {
        return target.catch((reason)=>{
            this.Show = true;
            this.ErrorMessage = new SimpleModalMessageModel('Something went wrong',reason.message,onErrorMessageHide)
        });
    }
}