import { AppSettings } from '../../Settings/Settings';
import BaseHttpHelper from '../../Common/HttpHelpers/BaseHttpHelper';
import SuccessReportModel from '../SubElements/Success/Models/SuccessReportModel';
import ErrorWarningReportModel from '../SubElements/ErrorWarnings/Models/ErrorWarningReportModel';
import ExpiredReportModel from '../SubElements/Expired/Models/ExpiredReportModel';
import CourseInfoResponseModel from '../Models/CourseInfoResponseModel';
import PagingModel from '../../Common/Models/PagingModel';
import LocalStorage from '../../Common/Services/LocalStorage';
import Authorization from '../../Authorization/Services/AuthorizationService';

export default class CourseHttpHelper {

    private static course: string = 'Course'

    static GetSuccess(pageNumber: number, pageSize: number, providerId: string): Promise<PagingModel<SuccessReportModel>> {
        let getAllSuccessCourses = 'getSuccessCoursesByPage';
        let params = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            providerId: providerId
        }
        let url = `${AppSettings.BaseUrl}/${this.course}/${getAllSuccessCourses}`;
        return BaseHttpHelper.SendGetRequest<PagingModel<SuccessReportModel>>(url,params)
    }

    static GetSuccessRetirement(pageNumber: number, pageSize: number, providerId: string): Promise<PagingModel<SuccessReportModel>> {
        let getAllSuccessCourses = 'getSuccessCoursesByPageRetirement';
        let params = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            providerId: providerId
        }
        let url = `${AppSettings.BaseUrl}/${this.course}/${getAllSuccessCourses}`;
        return BaseHttpHelper.SendGetRequest<PagingModel<SuccessReportModel>>(url,params)
    }

    static GetWarnings(pageNumber: number, pageSize: number,providerId: string): Promise<PagingModel<ErrorWarningReportModel>> {
        let getAllErrorWarningCourses = 'getAllCoursesWithWarningsByPage';
        let params = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            providerId: providerId
        }
        let url = `${AppSettings.BaseUrl}/${this.course}/${getAllErrorWarningCourses}`;
        return BaseHttpHelper.SendGetRequest<PagingModel<ErrorWarningReportModel>>(url, params)
    }

    static GetWarningsRetirement(pageNumber: number, pageSize: number,providerId: string): Promise<PagingModel<ErrorWarningReportModel>> {
        let getAllErrorWarningCourses = 'getAllCoursesWithWarningsByPageRetirement';
        let params = {
            pageSize: pageSize,
            pageNumber: pageNumber,
            providerId: providerId
        }
        let url = `${AppSettings.BaseUrl}/${this.course}/${getAllErrorWarningCourses}`;
        return BaseHttpHelper.SendGetRequest<PagingModel<ErrorWarningReportModel>>(url, params)
    }

    static GetExpired(pageSize: number, pageNumber: number): Promise<ExpiredReportModel[]> {
        let getAllExpiredCourses = 'getAllExpiredCourses';
        let params = {
            pageSize: pageSize,
            pageNumber: pageNumber
        }
        let url = `${AppSettings.BaseUrl}/${this.course}/${getAllExpiredCourses}`;
        return BaseHttpHelper.SendGetRequest<ExpiredReportModel[]>(url, params)
    }

    static Discard(providerId: string): Promise<any> {
        var providers = 'course'
        var url = `${AppSettings.BaseUrl}/${providers}/discard`;
        let params = providerId ? `?${new URLSearchParams({providerId:providerId}).toString()}` : '';
        return fetch(`${url}${params}`, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer '+LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            method: 'GET',
        }).then(response=> {
            if (response.status === 401) {
                Authorization.redirectUrl();
                return null;
            }
        });
    }

    static DiscardRetirement(providerId: string): Promise<any> {
        var providers = 'course'
        var url = `${AppSettings.BaseUrl}/${providers}/discardRetirement`;
        let params = providerId ? `?${new URLSearchParams({providerId:providerId}).toString()}` : '';
        return fetch(`${url}${params}`, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer '+LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            method: 'GET',
        }).then(response=> {
            if (response.status === 401) {
                Authorization.redirectUrl();
                return null;
            }
        });
    }
}