import { AppSettings } from '../../Settings/Settings';
import BaseHttpHelper from '../../Common/HttpHelpers/BaseHttpHelper';
import LocalStorage from '../../Common/Services/LocalStorage';
import NewAttributeModel from '../Models/NewAttributeModel';
import NewPasswordModel from '../Models/NewPasswordModel';

export default class EditUserHttpHelper {

    static EditAttribute(model: NewAttributeModel): Promise<Response | Promise<never>> {
        var user = 'User'
        var url = `${AppSettings.BaseUrl}/${user}/edit-user-data`;
        // return BaseHttpHelper.SendPostRequest<string>(url, null, model);
        return fetch(url, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer ' + LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model),
            method: 'POST',
        }).then((res) => {
            if (res.status === 400) {
                return res.json().then((x) => {
                    throw x;
                })
            } else {
                return res;
            }
        });
    }

    static EditPassword(model: NewPasswordModel): Promise<Response | Promise<never>> {
        var user = 'User'
        var url = `${AppSettings.BaseUrl}/${user}/edit-user-password`;
        // return BaseHttpHelper.SendPostRequest<string>(url, null, model);
        return fetch(url, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer ' + LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model),
            method: 'POST',
        }).then((res) => {
            if (res.status === 400) {
                return res.json().then((x) => {
                    throw x;
                })
            } else {
                return res;
            }
        });
    }
}