const AppSettings = {
    //BaseUrl: '/api',
    BaseUrl: process.env.REACT_APP_BASE_URL, // set value in .env to your own envrionment on local
    //BaseUrl: 'https://localhost:5001/api',
    // BaseUrl: "https://csod-contentprovider.conveyor.cloud/api",
    SignalREndpoint: '/courseshub',
    //SignalREndpoint: 'https://localhost:5001/courseshub',
    ShowedTableEntriesCount: 20,
    Environment: process.env.REACT_APP_STAGE // set value in .env to your own envrionment on local
}

export { AppSettings }