import { AppSettings } from '../../Settings/Settings';
import ProviderModel from '../Models/ProviderModel';
import BaseHttpHelper from '../../Common/HttpHelpers/BaseHttpHelper';
import ProviderResponseModel from '../Models/ProviderResponseModel';
import CountModel from '../Models/CountModel';

export default class ProviderHttpHelper {

    static GetProviders(): Promise<ProviderResponseModel[]> {
        var providers = 'provider'
        var url = `${AppSettings.BaseUrl}/${providers}/getListProviders`;
        return BaseHttpHelper.SendGetRequest<ProviderResponseModel[]>(url)
    }

    static GetTroubleFile() {
        var url = `${AppSettings.BaseUrl}/File/trouble`;
        return BaseHttpHelper.DownloadFile(url);
    }

    static GetZipFile() {
        var url = `${AppSettings.BaseUrl}/File/samplezip`;
        return BaseHttpHelper.DownloadFile(url);
    }

    static GetMetadataFile() {
        var url = `${AppSettings.BaseUrl}/File/samplemetadata`;
        return BaseHttpHelper.DownloadFile(url);
    }

    static GetRetirementSampleFile() {
        var url = `${AppSettings.BaseUrl}/File/retirementsamplefile`;
        return BaseHttpHelper.DownloadFile(url);
    }

    static GetRetirementTemplateFile() {
        var url = `${AppSettings.BaseUrl}/File/retirementtemplate`;
        return BaseHttpHelper.DownloadFile(url);
    }

  static GetFaqFile() {
    var url = `${AppSettings.BaseUrl}/File/faq`;
    return BaseHttpHelper.DownloadFile(url);
  }

    static GetCourseExcelFile(providerId: string) {
        var url = `${AppSettings.BaseUrl}/Provider/GetActiveCoursesExcelFile` + '?providerId=' + providerId;
        return BaseHttpHelper.DownloadFile(url);
    }

    static GetCoursesScheduledToRetire(providerId: string){
        var url = `${AppSettings.BaseUrl}/Course/coursesScheduledToRetireAsXLSXfile` + '?providerId=' + providerId;
        return BaseHttpHelper.DownloadFile(url);
    }

    static GetCount(providerId: string): Promise<CountModel>{
        var providers = 'provider'
        let params = {
            providerId: providerId
        }
        var url = `${AppSettings.BaseUrl}/${providers}/getCurrentCount`;
        return BaseHttpHelper.SendGetRequest<CountModel>(url,params);
    }
    static GetSyncedCoursesMetaDataPresignedURL(journalId: string):Promise<any>{
        var url = `${AppSettings.BaseUrl}/Course/syncedCoursesMetadataPreSingedURL` + '?journalId=' + journalId;
       return BaseHttpHelper.SendGetRequest<any>(url);
       
    }

}
