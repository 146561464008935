import React from "react";
import "./ReleaseNote.css";
export const ReleaseNotes = () => {
  return (
    <>
      <div>New Release Notes - November 4th 2021</div>
      <div className="table">
        <table>
          <tr>
            <th>Feature</th>
            <th>Description</th>
          </tr>
          <tr className="release-note-tr">
            <td className="release-note-point">
              New Excel Metadata Template with Macro validations
            </td>
            <td className="release-note-point">
              Excel Metadata Template now has validations capabilities. Older
              Excel will work too. However we advice to download the newer
              Metadata Template.xlsm from New Course Addition instructions
              section. Hit "Enable macro" when prompted. It will validate the
              mandatory column. The Excel validations acts as a guide and not as
              a enforcer . Which means you can upload the zip even if there are
              excel validation errors.
            </td>
          </tr>
          <tr className="release-note-tr">
            <td className="release-note-point">Thumbnail error improvement</td>
            <td className="release-note-point">....</td>
          </tr>
          <tr className="release-note-tr">
            <td className="release-note-point">Case sensitivity issue for filename fix</td>
            <td className="release-note-point">...</td>
          </tr>
        </table>
      </div>
    </>
  );
};
