import { Spinner } from 'react-bootstrap';
import React ,{ Component } from 'react';

interface TitledLoaderProps {
    title: string
    outlined: boolean
}

export default class TitledSpinner extends Component<TitledLoaderProps> {
    render() {
        return <div className={'loader-container ' + (this.props.outlined? 'outlined' : '')}>
            <h2 className='text-center'>{this.props.title}</h2>
            <div className="d-flex justify-content-center">
                <Spinner className='loader-spinner' animation='border' variant='success' />
            </div>
        </div>
    }
}