import * as React from 'react';
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CurrentPartnerStore from '../../CurrentPartner/Store/CurrentPartnerStore';
import ProgressBar from '../../Progress/Components/ProgressBar';
import ReportStore from '../../Report/Stores/ReportStore';
import ProgressStore from '../../Progress/Stores/ProgressStore';
import SyncStatus from '../../Report/SubElements/Success/Models/SyncStatus';
import './SyncingComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { RouterStore } from 'mobx-react-router';
import ProviderStore from '../../Providers/Stores/ProviderStore';

interface ProgressComponentProps {
    progress?: ProgressStore,
    currentPartner?: CurrentPartnerStore,
    report?: ReportStore,
    routing?: RouterStore,
    provider: ProviderStore,
}

@inject('progress', 'provider', 'currentPartner', 'report', 'routing')
@observer
export default class SyncingComponent extends Component<ProgressComponentProps> {

    backToUpload = (): void => {
        this.props.routing!.replace('/partner/upload');
    }

    backToAllPartner = async ():Promise<any> => {
        let count = await this.props.provider.getActiveCourseCount(this.props.currentPartner.selectedPartner.providerId);
        this.props.currentPartner.selectedPartner.activeCourseCount = count.numberOfCourses;
        this.props.currentPartner.setJournal(count.journal);
        this.props.routing!.replace('/partner');
    }

    render() {
        if (this.props.report.SyncSuccess == SyncStatus.Synced) return <div className='sync-container'>
            <div className="sync-text-block">
                <h2>{this.props.report.Success.items.length + " Courses Successfully Uploaded"}</h2>
            </div>
            <div className="sync-icon-success-block">
                <FontAwesomeIcon size="6x" className="sync-icon" icon={faCheckCircle} />
            </div>
            <div className="sync-links">
                <span className="new-user-link margin-link-sync" onClick={this.backToAllPartner.bind(this)}>Back to Manage Content</span>
                <span className="new-user-link margin-link-sync" onClick={this.backToUpload.bind(this)}>Back to Add New Courses</span>
            </div>
        </div>
        else return <div className='sync-container'>
            <div className="sync-text-block">
                <h2>{"Syncing " + this.props.report.Success.items.length + " Now"}</h2>
            </div>
            <div className='row progress-sync'>
                <ProgressBar percent={this.props.progress.currentProcess.Percent}></ProgressBar>
            </div>
            <div className="sync-text">
                <span className="text-sync-info">Please do not close the browser while syncing</span>
                <span className="text-sync-info">Doing so can result in a loss of data</span>
            </div>
        </div>
    }

}