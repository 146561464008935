import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom'
import { inject, observer } from "mobx-react";
import { Navbar, Nav, Form, Table } from "react-bootstrap";
import logo from "../../Cornerstone_Logo.png";
import "./ManageAccount.css";
import { RouterStore } from "mobx-react-router";
import ManageAccountStore from "../Stores/ManageAccountStore";
import { SortingState } from '../../Common/Sorting/SortState';
import SortingComponent from "../../Common/Sorting/Sorting";
import TitledSpinner from "../../Common/Components/TitledSpinner/TitledSpinner";
import LoaderStore from "../../Common/Stores/LoaderStore";
import Pagination from "../../Common/Components/Pagination/Pagination";
import PaginationWithOutPromise from "../../Common/Components/Pagination/PaginationWithOutPromise";

interface ManageAccountProps {
    manageAccount?: ManageAccountStore,
    routing?: RouterStore,
    loader: LoaderStore
}

interface ManageAccountState {
    loadingData: boolean,
    sortingStateName: SortingState
    sortingStateDate: SortingState
}

@inject('manageAccount', 'routing', 'loader')
@observer
export default class ManageAccount extends Component<ManageAccountProps, ManageAccountState> {

    state = {
        loadingData: true,
        sortingStateName: SortingState.NotSorted,
        sortingStateDate: SortingState.NotSorted
    }

    componentDidMount() {
        this.props.loader.hideLoading();
        this.props.manageAccount.loadUsers().then(() => {
            this.setState({ loadingData: false });
            this.HandleChangePartnerName(SortingState.Down);
        });
    }

    HandleChangePartnerName = (newState: SortingState) => {
        this.setState({
            sortingStateName: newState,
            sortingStateDate: SortingState.NotSorted
        })
        this.props.manageAccount.LoadedUsers = this.props.manageAccount.LoadedUsers.slice().sort((n1, n2) => {

            return newState === SortingState.Down ? (n1.partner > n2.partner ? 1 : -1) : (n1.partner < n2.partner ? 1 : -1)
        });
        this.handleOnChangePage(this.props.manageAccount.Users.currentPage);
    }

    HandleChangeUserName = (newState: SortingState) => {
        this.setState({
            sortingStateName: SortingState.NotSorted,
            sortingStateDate: newState
        })
        this.props.manageAccount.LoadedUsers = this.props.manageAccount.LoadedUsers.slice().sort((n1, n2) => {

            return newState === SortingState.Down ? (n1.username > n2.username ? 1 : -1) : (n1.username < n2.username ? 1 : -1)
        });
        this.handleOnChangePage(this.props.manageAccount.Users.currentPage);
    }

    newUser = (): void => {
        this.props.routing!.replace('/create-user');
    }

    setSelected(partner: string, email: string, username: string) {
        this.props.manageAccount.setEditedUser(partner, username, email);
        this.props.routing!.replace('/edit-user');
    }

    onSearchChange(event: any) {
        this.props.manageAccount.setSearch(event.target.value);
    }

    render() {
        return (
            <Fragment>
                <div className="link-new-user-block">
                    <span className="new-user-link" onClick={this.newUser.bind(this)}>Create new user</span>
                </div>
                <div className="user-table-block">
                    <div>
                        <div className="user-label-block">
                            <h2>Partners</h2>
                        </div>
                        {/* <div className="search-block">
                            <Form.Control className="search-input" type="text" placeholder="&#128269; Search" onChange={this.onSearchChange.bind(this)} value={this.props.manageAccount.search} />
                        </div> */}
                    </div>
                    {this.state.loadingData ? <TitledSpinner title='Loading...' outlined={false} /> :
                        <Fragment>
                            <div className='row table-row table-striped'>
                                {/* <div className='col-xl-8 offset-xl-1' > */}
                                <Table responsive striped hover className="journal-table">
                                    <thead>
                                        <tr>
                                            <th scope='col' className="thead-no-border">Partner Name <SortingComponent Click={this.HandleChangePartnerName} SortingState={this.state.sortingStateName} /></th>
                                            <th scope='col' className="thead-no-border">Username <SortingComponent Click={this.HandleChangeUserName} SortingState={this.state.sortingStateDate} /></th>
                                            <th scope='col' className="thead-no-border">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.mapUserToTable()}
                                    </tbody>
                                </Table>
                                {/* </div> */}
                            </div>
                            <PaginationWithOutPromise currentPageIndex={this.props.manageAccount.Users.currentPage} listPages={this.props.manageAccount.Users.totalPages} handleChangePage={this.handleOnChangePage}></PaginationWithOutPromise>
                        </Fragment>
                    }
                </div>
            </Fragment>
        )
    }


    handleOnChangePage = (newCurrentPage: number): any => {
        this.props.manageAccount.Users.currentPage = newCurrentPage;
        if (newCurrentPage == this.props.manageAccount.Users.totalPages) {
            this.props.manageAccount.Users.items = this.props.manageAccount.LoadedUsers.slice((newCurrentPage * 20) - 20, this.props.manageAccount.LoadedUsers.length);
        }
        else {
            this.props.manageAccount.Users.items = this.props.manageAccount.LoadedUsers.slice((newCurrentPage * 20) - 20, (newCurrentPage * 20));
        }
    }

    mapUserToTable() {
        return this.props.manageAccount.Users.items.map(x =>
            <Fragment>
                <tr>
                    <td>{x.partner}</td>
                    <td>{x.email}</td>
                    <td><span className="new-user-link" onClick={this.setSelected.bind(this, x.partner, x.email, x.username)}>Edit</span></td>
                </tr>
            </Fragment>
        );
    }
}