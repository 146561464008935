import { observable, action, computed } from 'mobx';
import SuccessReportModel from '../SubElements/Success/Models/SuccessReportModel';
import ErrorWarningReportModel from '../SubElements/ErrorWarnings/Models/ErrorWarningReportModel';
import ExpiredReportModel from '../SubElements/Expired/Models/ExpiredReportModel';
import PagingModel from '../../Common/Models/PagingModel';
import CourseHttpHelper from '../HttpHelpers/CourseHttpHelper';
import { AppSettings } from '../../Settings/Settings';
import SyncStatus from '../SubElements/Success/Models/SyncStatus';

export default class ReportStore {
    @observable Success: PagingModel<SuccessReportModel> = observable({ totalPages: 0, currentPage: 0, items: [] });
    @observable ErrorWarnings: PagingModel<ErrorWarningReportModel> = observable({ totalPages: 0, currentPage: 0, items: [] });
    @observable Expired: PagingModel<ExpiredReportModel> = null;//observable({ TotalPages: 0, currentPage: 0, Items: [] });
    @observable SyncSuccess: SyncStatus = SyncStatus.NotSynced;
    @observable isShowModal: boolean = false;
    @observable syncModal: boolean = false;
    @observable discardModal: boolean = false;
    @observable text: string[] = observable([]);
    @observable filePreSignedUrl: string = null;

    @action setModal(state: boolean) {
        this.isShowModal = state;
    }

    @action setSyncModal(state: boolean) {
        this.syncModal = state;
    }

    @action setDiscardModal(state: boolean) {
        this.discardModal = state;
    }

    @action setText(text: string[]) {
        this.text = text;
    }

    @computed get getText() {
        return this.text;
    }

    @computed get getModalState() {
        return this.isShowModal;
    }

    @computed get getSyncModalState() {
        return this.syncModal;
    }

    @computed get getDiscardModalState() {
        return this.discardModal;
    }
    @action setFilePreSignedUrl(state: string) {
        this.filePreSignedUrl = state;
    }

    @action
    loadSuccess = (providerId: string, currentPage: number = 1): Promise<void | PagingModel<SuccessReportModel>> => {
        return CourseHttpHelper.GetSuccess(currentPage, AppSettings.ShowedTableEntriesCount, providerId)
            .then((res) => {
                this.Success = new PagingModel<SuccessReportModel>(
                    res.totalPages,
                    res.items.map((res) => {
                        return new SuccessReportModel(
                            res.courseID, res.courseID,
                            res.title, res.description,
                            res.duration,
                            res.languages,
                            0
                        )
                    }),
                    currentPage
                );
            });
    }

    @action
    loadWarningError = (providerId: string, currentPage: number = 1): Promise<void | PagingModel<ErrorWarningReportModel>> => {
        return CourseHttpHelper.GetWarnings(currentPage, AppSettings.ShowedTableEntriesCount, providerId)
            .then((res) => {
                this.ErrorWarnings = new PagingModel<ErrorWarningReportModel>(
                    res.totalPages,
                    res.items.map((res) => {
                        return new ErrorWarningReportModel(
                            res.courseID,
                            res.courseID,
                            res.title,
                            res.errorWarning,
                            res.messageLog,
                            0
                        )
                    }),
                    currentPage
                );
            });
    }

    @action
    loadExpired = (currentPage: number = 1) => {
        return CourseHttpHelper.GetExpired(3, currentPage).then((res) => this.Expired = new PagingModel<ExpiredReportModel>(3, res, currentPage));
    }
}