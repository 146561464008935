import { AppSettings } from '../../Settings/Settings';
import BaseHttpHelper from '../../Common/HttpHelpers/BaseHttpHelper';
import CreateUserModel from '../Models/CreateUserModel';
import LocalStorage from '../../Common/Services/LocalStorage';

export default class CreateUserHttpHelper {

    static CreateUser(model: CreateUserModel): Promise<Response|Promise<never>> {
        var user = 'User'
        var url = `${AppSettings.BaseUrl}/${user}/register`;
        // return BaseHttpHelper.SendPostRequest<string>(url, null, model);
        return fetch(url, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer ' + LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(model),
            method: 'POST',
        }).then((res) => {
            if(res.status === 400) {
                return res.json().then((x) => {
                    throw x;
                })
            } else {
                return res;
            }
        });
    }
}