import { observable, action, computed } from "mobx";
import CurrentPartnerHttpHelper from "../HttpHelpers/CurrentPartnerHttpHelper";
import CurrentPartnerModel from "../Models/CurrentPartnerModel";
import ProviderModel from "../../Providers/Models/ProviderModel";
import ProviderStatus from "../../Providers/Models/ProviderStatus";
import JournalItemModel from "../../Providers/Models/JournalItemModel";

const COUNT_ON_PAGE: number = 10;

class CurrentPartnerStore {
    @observable selectedPartner: ProviderModel = observable({
        providerId: '',
        providerName: '',
        lastUpdate: new Date(),
        providerStatus: ProviderStatus.Free,
        activeCourseCount: 0,
        retirementStatus: ProviderStatus.Free
    });

    @observable currentPartner: CurrentPartnerModel = observable({
        name: '',
        image: '',
        activeCourseCount: 0,
        journal: []
    });

    @observable journalAll: JournalItemModel[] = observable([]);
    @observable journal: JournalItemModel[] = observable([]);
    @observable journalPage: number = 0;
    @observable journalTotalPages: number = 0;

    @observable isLoadFile: boolean = false;
    @observable updateOnlyMetadata: boolean = false;

    @action setLoadFileTrue(){
        this.isLoadFile = true;
    }

    @action setLoadFileFalse(){
        this.isLoadFile = false;
    }

    @computed get getFileLoadState(){
        return this.isLoadFile;
    }

    @action setJournal(journal: JournalItemModel[]) {
        this.journalAll = this.parseDate(journal);
        this.journalPage = 1;
        let total = this.journalAll.length / COUNT_ON_PAGE;
        if (this.journalAll.length % COUNT_ON_PAGE != 0) total++;
        this.journalTotalPages = Math.floor(total);
        if (this.journalTotalPages <= 1) this.journal = this.journalAll;
        else this.journal = this.journalAll.slice(0, COUNT_ON_PAGE);

    }

    private parseDate(journal: JournalItemModel[]) {
        for (let i = 0; i < journal.length; i++) {
            journal[i].syncDate = new Date(journal[i].syncDate);
        }
        return journal;
    }

    @action setStatusFree() {
        this.selectedPartner.providerStatus = ProviderStatus.Free;
    }

    @action setRetirementFree() {
        this.selectedPartner.retirementStatus = ProviderStatus.Free;
    }

    @computed get getStatus() {
        return this.selectedPartner.providerStatus;
    }

    @computed get getRetirementStatus() {
        return this.selectedPartner.retirementStatus;
    }

    @computed get getJournal() {
        return this.journal;
    }

    @observable errorFileType: boolean = false;

    @action setSelectedPartner(partner: ProviderModel) {
        this.selectedPartner = partner;
    }

    @action setFileTypeError(error: boolean) {
        this.errorFileType = error;
    }

    @action loadProvider = () => {
        return CurrentPartnerHttpHelper.GetProvider().then(
            x => {
                this.currentPartner = x;
            });
    }

    @computed get getErrorState() {
        return this.errorFileType;
    }

    @action setUpdateOnlyMetadata(updateOnlyMetadata: boolean){
        this.updateOnlyMetadata = updateOnlyMetadata;
    }
}

export default CurrentPartnerStore;