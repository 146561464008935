import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory as createHistory } from 'history'
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router, Switch, Route } from 'react-router';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import * as serviceWorker from './serviceWorker';
import AuthComponent from './Authorization/Components/AuthComponent';
import ProviderStore from './Providers/Stores/ProviderStore';
import ReportStore from './Report/Stores/ReportStore';
import ProgressStore from './Progress/Stores/ProgressStore';
import ErrorMessageStore from './Common/Stores/ErrorMessageStore';
import WarningMessageStore from './Common/Stores/WarningMessageStore';
import LoaderStore from './Common/Stores/LoaderStore';
import FullTextShowStore from './Common/Stores/FullTextShowStore';
import HeaderStore from './Layout/Stores/HeaderStore';
import ManageAccountStore from './ManageAccounts/Stores/ManageAccountStore';
import CurrentPartnerStore from './CurrentPartner/Store/CurrentPartnerStore';
import RetirementStore from './Retirement/Store/RetirementStore';

const browserHistory = createHistory();
const routingStore = new RouterStore();
const retirementStore = new RetirementStore();
const loaderStore = new LoaderStore();
const providerStore = new ProviderStore();
const errorMessageStore = new ErrorMessageStore();
const reportStore = new ReportStore();
const headerStore = new HeaderStore();
const manageAccount = new ManageAccountStore();
const currentPartner = new CurrentPartnerStore();

const stores = {
  routing: routingStore,
  loader: loaderStore,
  provider: providerStore,
  retirementStore: retirementStore,
  errorMessage: errorMessageStore,
  textShow: new FullTextShowStore,
  report: reportStore,
  progress: new ProgressStore(loaderStore,providerStore,errorMessageStore,routingStore,reportStore),
  warningMessage: new WarningMessageStore,
  header: headerStore,
  manageAccount: manageAccount,
  currentPartner: currentPartner
};

const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(<Provider {...stores}>
  <Router history={history}>
    <Switch>
      <Route path='/auth' component={AuthComponent} ></Route>
      <Route path='/' component={App} ></Route>
    </Switch>
  </Router>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
