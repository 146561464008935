import * as React from 'react'
import { Component } from 'react';
import './Retirement.css';
import { inject, observer } from 'mobx-react';
import ProviderStore from '../../Providers/Stores/ProviderStore';
import { RouterStore } from 'mobx-react-router';
import { Button, OverlayTrigger, Tooltip, Table, Dropdown } from 'react-bootstrap';
import { SortingState } from '../../Common/Sorting/SortState';
import UploadComponent from '../../Upload/Components/UploadComponent';
import ProviderHttpHelper from '../../Providers/HttpHelpers/ProviderHttpHelper';
import ProviderModel from '../../Providers/Models/ProviderModel';
import ProviderStatus from '../../Providers/Models/ProviderStatus';
import RetirementStore from '../Store/RetirementStore';
import CurrentPartnerStore from '../../CurrentPartner/Store/CurrentPartnerStore';
import UploadRetirement from './Upload/UploadRetirement';
import LoaderStore from '../../Common/Stores/LoaderStore';

interface RetirementProps {
    currentPartner: CurrentPartnerStore,
    provider: ProviderStore,
    routing: RouterStore,
    retirementStore: RetirementStore ,
    loader? : LoaderStore,
}

const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
};

@inject('currentPartner', 'provider', 'routing', 'retirementStore' , 'loader')
@observer
export default class Retirement extends Component<RetirementProps> {

    componentWillMount() {
        this.props.retirementStore.initDate();
    }

    async backToPartner() {
        let count = await this.props.provider.getActiveCourseCount(this.props.currentPartner.selectedPartner.providerId);
        this.props.currentPartner.selectedPartner.activeCourseCount = count.numberOfCourses;
        this.props.currentPartner.setJournal(count.journal);
        this.props.routing!.replace('/partner');
    }

    async downloadREtirementTemplateFile() {
        let response = await ProviderHttpHelper.GetRetirementTemplateFile();
        await this.download('Retirement Template.xlsx', response);
    }

    async downloadRetirementSampleFile() {
        let response = await ProviderHttpHelper.GetRetirementSampleFile();
        await this.download('Retirement Sample File.xlsx', response);
    }

    async download(name: string, response: Response) {
        const url = window.URL.createObjectURL(new Blob([await response.blob()]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }

    getOnProcessingClickHandler = (providerStatus: ProviderStatus) => {
        switch (providerStatus) {
            case ProviderStatus.Processing: return this.redirectToProcessing;
            case ProviderStatus.Unsynced: return this.redirectToReport;
        }
    }

    redirectToReport = (provider: ProviderModel): void => {
        this.props.provider!.CurrentProvider = provider;
        this.props.routing!.push(`/retirement/report`);
    }

    redirectToProcessing = (provider: ProviderModel): void => {
        this.props.provider!.CurrentProvider = provider;
        this.props.routing!.push(`/progress`);
    }

    async downloadScheduledToRetireExcelFile() {
        try {
            this.props.currentPartner.setLoadFileTrue();
            this.props.loader.showLoading('Downloading Excel report...', () => { });
            let response = await ProviderHttpHelper.GetCoursesScheduledToRetire(this.props.currentPartner.selectedPartner.providerId);
            await this.download(this.props.currentPartner.selectedPartner.providerName + '_upcoming_retiring_courses.xlsx', response);
            this.props.currentPartner.setLoadFileFalse();
            this.props.loader.hideLoading();
        }
        catch (error) {
            this.props.currentPartner.setLoadFileFalse();
        }
    }

    
    render() {
        return (
            <React.Fragment>
                <div className="link-back-block">
                    <span className="new-user-link" onClick={this.backToPartner.bind(this)}>Back to Manage Content</span>
                </div>
                <div className="partner-name-grid-container">
                    <div className="left">
                      <span className="partner-upload-name"><b>{this.props.currentPartner.selectedPartner.providerName}</b></span>
                    </div>
                    <div className="right">
                      <span className="new-user-link" onClick={this.downloadScheduledToRetireExcelFile.bind(this)}>Download Upcoming Retirements (Excel File)</span>
                    </div>  
                </div>
               
                <div className="main-upload-block">
                    <span className="main-upload-label"><b>Submit Courses for Retirement</b></span>
                    <Button variant="secondary" hidden={this.props.currentPartner.getRetirementStatus == ProviderStatus.Free}
                        onClick={() => { this.getOnProcessingClickHandler(this.props.currentPartner.selectedPartner.retirementStatus)(this.props.currentPartner.selectedPartner) }}>View Unsynced Courses</Button>
                    <span className="instruction-label"><b>Follow these instruction to submit courses for retirement</b></span>
                    <span className="number-instr-retirement one-line-with-dropdown">1) In order to submit courses for retirement, please select a retirement date from the list of available dates: </span>
                    <Dropdown className="date-dropdown">
                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="custom-dropdown-tggl">
                            {this.props.retirementStore.getSelectedDate}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.createAllDates()}
                        </Dropdown.Menu>
                    </Dropdown>
                    <span className="one-line-retirement-des error-color"><i>*At the moment we are only processing retirements in March, June, September, & December. Please do turn off courses until the end of the retirement month.</i></span>
                    <span className="one-line-retirement-des last-des-mrg"><i>*If you wish to retire courses immediately, please reach out directly to <a href='mailto:contentupdates@csod.com' className='email-link'>contentupdates@csod.com</a> with your request.</i></span>
                    <span className="number-instr-retirement">2) Once you have selected a retirement date above, please download the <span className="new-user-link" onClick={this.downloadREtirementTemplateFile.bind(this)}>Retirement template</span>. You will need to fill out the template to indicate which courses you wish to retire. </span>
                    <span className="number-instr-retirement">3) After you have downloaded the Retirement template, please refer to the ‘Retired Courses’ tab in the metadata template. </span>
                    <span className="number-instr-retirement">4) On the ‘Retire’ tab, please fill out: </span>
                    <span className="one-line-retirement">a) <b>Retired Course ID</b>: All courses must have an original Course ID that was referenced when the course was active. This ID should be consistently utilized across all documentation provided to Cornerstone. Eg. LH101</span>
                    <span className="one-line-retirement">b) <b>Retired Course Title</b>: The course titles must be the same title that was referenced when the course was active.</span>
                    <span className="one-line-retirement">c) <b>Replacement Course ID</b>: If no replacement was assigned, then indicate: “No Replacement ID”.</span>
                    <span className="one-line-retirement">d) <b>Replacement Course Title</b>: If no replacement was assigned, then indicate: “No Replacement Title”.</span>
                    <span className="one-line-retirement">e) <b>Reason for Retirement</b>: Let us know the reason for retiring the course. A few examples for retiring a course are: the course is outdated, there is a new replacement course, and the course has errors.</span>
                    <span className="number-instr-retirement">5) Once you fill out the ‘Retired Courses’ tab, save the Retirement template as an xlsx/xls file.</span>
                    <span className="number-instr-retirement">6) Here is what your final excel file should look like: <span className="new-user-link" onClick={this.downloadRetirementSampleFile.bind(this)}>sample file</span>.</span>
                    <span className="number-instr-retirement last-mrg">7) Now please drag and drop your newly saved file below.</span>
                </div>
                <div className="error-center">
                    <label className='error-label error-center'>{this.props.retirementStore.getErrorState && 'Wrong file type'}</label>
                </div>
                {
                    this.props.currentPartner.getRetirementStatus != ProviderStatus.Free && <div className="error-center">
                        <label className='error-label error-center'>Your previously saved upload will be lost if you drag and drop new file</label>
                    </div>
                }

                <div className="error-center">
                    <label className='error-label error-center'>{this.props.retirementStore.getDateErrorState && 'You must pick a date'}</label>
                </div>
                <div className="upload-block">
                    <UploadRetirement />
                </div>
            </React.Fragment>
        );
    }

    selectDate(index: number) {
        this.props.retirementStore.setSelectedDate(index);
    }

    createAllDates() {
        let items = [];
        for (let i = 0; i < this.props.retirementStore.getAllRetirementsDate.length; i++) {
            items.push(
                <Dropdown.Item onClick={this.selectDate.bind(this, i)}>{this.props.retirementStore.getAllRetirementsDate[i].toLocaleString("en-US", options)}</Dropdown.Item>
            )
        }
        return items;
    }
}