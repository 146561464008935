import HeaderStore from "../Stores/HeaderStore";
import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom'
import { inject, observer } from "mobx-react";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../Cornerstone_Logo.png";
import "./Header.css";
import { RouterStore } from "mobx-react-router";
import {AppSettings} from "../../Settings/Settings";

interface HeaderProps {
    header?: HeaderStore,
    routing?: RouterStore
}

@inject('header', 'routing')
@observer
export default class Header extends Component<HeaderProps> {

    render() {
        return (
            <Fragment>
                <Navbar className="navbar-main-style">
                    <Navbar.Brand >
                    {AppSettings.Environment === "prod" && 
                    <div className="brand-div">
                        <img src={logo} className='brand'></img>
                    </div>}
                    {AppSettings.Environment !== "prod" && 
                        <div className="brand-div">
                            <div className = "brand">
                                <span className="textLogo">CUT</span>
                                <span className ="textLogoEnv">
                                    {AppSettings.Environment}
                                </span>
                            </div>
                        </div>
                    }
                    </Navbar.Brand>
                    <Nav className="mr-auto"></Nav>
                    <Nav className="right-side-links">
                        <span className={"nav-link-solo " + (this.props.header.selected == 1 ? "active" : "")} onClick={this.redirectToMainPage.bind(this)}>MANAGE CONTENT</span>
                        {
                            (this.props.header.isAdmin &&
                                <span className={"nav-link-solo " + (this.props.header.selected == 2 ? "active" : "")} onClick={this.redirectToUsers.bind(this)}>MANAGE ACCOUNTS</span>)
                        }
                        <span className="nav-link-solo" onClick={this.logOut.bind(this)} >LOG OUT</span>
                    </Nav>
                </Navbar>
            </Fragment>
        )
    }

    redirectToMainPage = (): void => {
        this.props.header.setSelected(1);
        this.props.routing!.replace('/');
    }

    redirectToUsers = (): void => {
        this.props.header.setSelected(2);
        this.props.routing!.replace('/manage-accounts');
    }

    logOut = (): void => {
        this.props.header.logOut();
        this.props.routing!.replace('/auth');
    }
}