import ProviderModel from '../Models/ProviderModel';
import { observable, action } from 'mobx';
import ProviderHttpHelper from '../HttpHelpers/ProviderHttpHelper';
import ProviderStatus from '../Models/ProviderStatus';
import ProviderResponseModel from '../Models/ProviderResponseModel';
var apigClientFactory = require('aws-api-gateway-client').default;

export default class ProviderStore {
    @observable Providers: ProviderModel[] = observable([]);
    @observable CurrentProvider: ProviderModel = null;

    @action
    loadProviders = () => {
        return ProviderHttpHelper.GetProviders().then((res: ProviderResponseModel[]) => this.Providers = res.map(p => { return new ProviderModel(p.providerId, p.providerName, new Date(p.lastUpdate), this.mapProviderStatus(p.isHaveNotSyncCources), this.mapProviderStatus(p.isHaveNotSyncRetirement), 0) }));
    }
    mapProviderStatus(isHaveNotSyncCources: boolean) {
        if (isHaveNotSyncCources) {
            return ProviderStatus.Unsynced;
        } else {
            return ProviderStatus.Free;
        }
    }

    @action
    setCurrentProvider = (provider: ProviderModel) => {
        this.CurrentProvider = provider;
    }

    @action
    setStatus = (providerId: string, status: ProviderStatus) => {
        if (this.Providers.length == 0) {
            return;
        }
        if (this.CurrentProvider != null) {
            if (this.CurrentProvider.providerId == providerId) {
                this.CurrentProvider = new ProviderModel(this.CurrentProvider.providerId, this.CurrentProvider.providerName, this.CurrentProvider.lastUpdate, status, this.CurrentProvider.retirementStatus, this.CurrentProvider.activeCourseCount);
            }
        }
        let concreteProviderIndex = this.Providers.findIndex(p => p.providerId == providerId);
        if (concreteProviderIndex != -1) {
            this.Providers[concreteProviderIndex] = new ProviderModel(this.Providers[concreteProviderIndex].providerId, this.Providers[concreteProviderIndex].providerName, this.Providers[concreteProviderIndex].lastUpdate, status,this.Providers[concreteProviderIndex].retirementStatus, this.Providers[concreteProviderIndex].activeCourseCount);
        }
    }

    @action
    setStatusRetired = (providerId: string, status: ProviderStatus) => {
        if (this.Providers.length == 0) {
            return;
        }
        if (this.CurrentProvider != null) {
            if (this.CurrentProvider.providerId == providerId) {
                this.CurrentProvider = new ProviderModel(this.CurrentProvider.providerId, this.CurrentProvider.providerName, this.CurrentProvider.lastUpdate,this.CurrentProvider.providerStatus, status, this.CurrentProvider.activeCourseCount);
            }
        }
        let concreteProviderIndex = this.Providers.findIndex(p => p.providerId == providerId);
        if (concreteProviderIndex != -1) {
            this.Providers[concreteProviderIndex] = new ProviderModel(this.Providers[concreteProviderIndex].providerId, this.Providers[concreteProviderIndex].providerName, this.Providers[concreteProviderIndex].lastUpdate,this.Providers[concreteProviderIndex].providerStatus, status, this.Providers[concreteProviderIndex].activeCourseCount);
        }
    }

    @action
    getProvider = (providerId: string) => {
        return this.Providers.find(p => p.providerId == providerId);
    }

    @action async getActiveCourseCount(providerId: string) {
        let request = ProviderHttpHelper.GetCount(providerId);
        return await request.then(
            x => {
                return x;
            }
        )
    }
}