import * as React from 'react'
import { Component } from 'react';


interface ProgressBarProps {
    percent: number
}

export default class ProgressBar extends Component<ProgressBarProps>{
    render() {
        return <div className='progress w-100'>
            <div className='progress-bar progress-bar-striped progress-bar-animated ' role='progressbar' aria-valuenow={this.props.percent} aria-valuemin={0} aria-valuemax={100} style={{ width: this.props.percent + '%' }}>{this.props.percent} %</div>
        </div>
    }
}