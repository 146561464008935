import { AppSettings } from '../../Settings/Settings';
import Authorization from '../../Authorization/Services/AuthorizationService';
import LocalStorage from '../../Common/Services/LocalStorage';

export default class DownloadHttpHelper {

    static GetFile(isValidCources: boolean, providerId: string): Promise<Blob> {
        var subDommen = 'course/getUploadedCourcesAsXLSXFile'
        var url = `${AppSettings.BaseUrl}/${subDommen}`;
        return fetch(`${url}?isValidCources=${isValidCources}&providerId=${providerId}`, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer ' + LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            method: 'Get',
        }).then((response) => {
            if (response.status === 401) {
                Authorization.redirectUrl();
                return null;
            } else if (response.status === 400) {
                return response.json().then(x => {
                    throw x;
                });
            } else {
                return response.blob();
            }
        });
    }

    static GetFileREtirement(isValidCources: boolean, providerId: string): Promise<Blob> {
        var subDommen = 'course/getUploadedCourcesAsXLSXFileRetirement'
        var url = `${AppSettings.BaseUrl}/${subDommen}`;
        return fetch(`${url}?isValidCources=${isValidCources}&providerId=${providerId}`, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer ' + LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            method: 'Get',
        }).then((response) => {
            if (response.status === 401) {
                Authorization.redirectUrl();
                return null;
            } else if (response.status === 400) {
                return response.json().then(x => {
                    throw x;
                });
            } else {
                return response.blob();
            }
        });
    }
}