import * as React from 'react'
import './CurrentPartner.css'
import { Component } from 'react';
import CurrentPartnerStore from '../Store/CurrentPartnerStore';
import { inject, observer } from 'mobx-react';
import TitledSpinner from '../../Common/Components/TitledSpinner/TitledSpinner';
import ProviderStore from '../../Providers/Stores/ProviderStore';
import LocalStorage from '../../Common/Services/LocalStorage';
import { RouterStore } from 'mobx-react-router';
import { Button, OverlayTrigger, Tooltip, Table, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import SortingComponent from '../../Common/Sorting/Sorting';
import { SortingState } from '../../Common/Sorting/SortState';
import PaginationWithOutPromise from '../../Common/Components/Pagination/PaginationWithOutPromise';
import ProviderHttpHelper from '../../Providers/HttpHelpers/ProviderHttpHelper';
import LoaderStore from '../../Common/Stores/LoaderStore';
import JournalItemModel from '../../Providers/Models/JournalItemModel';
import {  toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ReleaseNotes } from '../../ReleaseNotes/ReleaseNote';
interface CurrentPartnerState {
    loadingData: boolean
    sortingStateCoursesSynced: SortingState
    sortingStateAction: SortingState
    sortingStateDate: SortingState
    sortingStateSyncedBy: SortingState
}

interface CurrentPartnerProps {
    currentPartner: CurrentPartnerStore,
    provider: ProviderStore,
    routing: RouterStore,
    loader?: LoaderStore
}

const COUNT_ON_PAGE: number = 10;


@inject('currentPartner', 'provider', 'routing')
@observer
export default class CurrentPartner extends Component<CurrentPartnerProps, CurrentPartnerState> {
    state = {
        loadingData: true,
        sortingStateCoursesSynced: SortingState.NotSorted,
        sortingStateAction: SortingState.NotSorted,
        sortingStateDate: SortingState.NotSorted,
        sortingStateSyncedBy: SortingState.NotSorted
    }

    componentDidMount() {
        if (LocalStorage.getData("role") != "Admin") {
            this.props.provider.loadProviders().then(async () => {
                this.props.currentPartner.selectedPartner = this.props.provider.Providers[0];
                let count = await this.props.provider.getActiveCourseCount(this.props.currentPartner.selectedPartner.providerId);
                this.props.currentPartner.selectedPartner.activeCourseCount = count.numberOfCourses;
                this.props.currentPartner.setJournal(count.journal);
                this.setState({ loadingData: false }); // delete after mocks
            }).catch((e)=> {
                localStorage.setItem('loadProviderErrorMsg', e.message ? e.message : 'Unknown error occured.');
                this.setState({ loadingData: false });
                this.logoutUserWithError('#provider_err');
            });
        }
        else this.setState({ loadingData: false });

        this.displayMsg();
        // this.props.currentPartner.loadProvider().then(() => {
        //this.setState({ loadingData: false })
        /// });
    }

    backToAllPartner = (): void => {
        this.props.routing!.replace('/');
    }

    goToUpload() {
        localStorage.setItem('action', 'Added');
        this.props.routing!.replace('/partner/upload');
    }

    logoutUserWithError(err: any) {
        LocalStorage.saveToken('');
        this.props.routing!.replace(`/auth${err}`);
    }

    goToRetirement() {
        this.props.routing!.replace('/partner/retirement');
    }

    async downloadExel() {
        try {
            this.props.currentPartner.setLoadFileTrue();
            let response = await ProviderHttpHelper.GetCourseExcelFile(this.props.currentPartner.selectedPartner.providerId);
            await this.download(this.props.currentPartner.selectedPartner.providerName + ' active courses.xlsx', response);
            this.props.currentPartner.setLoadFileFalse();
        }
        catch (error) {
            this.props.currentPartner.setLoadFileFalse();
        }
    }

    async download(name: string, response: Response) {
        const url = window.URL.createObjectURL(new Blob([await response.blob()]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }

    HandleChangeCourses = (newState: SortingState) => {
        this.setState({
            sortingStateCoursesSynced: newState,
            sortingStateAction: SortingState.NotSorted,
            sortingStateDate: SortingState.NotSorted,
            sortingStateSyncedBy: SortingState.NotSorted
        })
        this.props.currentPartner.journalAll = this.props.currentPartner.journalAll.slice().sort((n1, n2) => {
            return newState === SortingState.Down ? (n1.count > n2.count ? 1 : -1) : (n1.count < n2.count ? 1 : -1)
        });
        this.handleOnChangePage(this.props.currentPartner.journalPage);
    }

    HandleChangeAction = (newState: SortingState) => {
        this.setState({
            sortingStateAction: newState,
            sortingStateCoursesSynced: SortingState.NotSorted,
            sortingStateDate: SortingState.NotSorted,
            sortingStateSyncedBy: SortingState.NotSorted
        })
        this.props.currentPartner.journalAll = this.props.currentPartner.journalAll.slice().sort((n1, n2) => {
            return newState === SortingState.Down ? (n1.action > n2.action ? 1 : -1) : (n1.action < n2.action ? 1 : -1)
        });
        this.handleOnChangePage(this.props.currentPartner.journalPage);
    }

    HandleChangeDate = (newState: SortingState) => {
        this.setState({
            sortingStateAction: SortingState.NotSorted,
            sortingStateCoursesSynced: SortingState.NotSorted,
            sortingStateDate: newState,
            sortingStateSyncedBy: SortingState.NotSorted
        })
        this.props.currentPartner.journalAll = this.props.currentPartner.journalAll.slice().sort((n1, n2) => {
            return +(newState === SortingState.Down ? (n1.syncDate.getTime() < n2.syncDate.getTime() ? 1 : -1) :
                (n1.syncDate.getTime() > n2.syncDate.getTime() ? 1 : -1))
        });
        this.handleOnChangePage(this.props.currentPartner.journalPage);
    }

    HandleChangeSyncedBy = (newState: SortingState) => {
        this.setState({
            sortingStateAction: SortingState.NotSorted,
            sortingStateCoursesSynced: SortingState.NotSorted,
            sortingStateDate: SortingState.NotSorted,
            sortingStateSyncedBy: newState
        })
        this.props.currentPartner.journalAll = this.props.currentPartner.journalAll.slice().sort((n1, n2) => {
            return newState === SortingState.Down ? (n1.syncedBy > n2.syncedBy ? 1 : -1) : (n1.syncedBy < n2.syncedBy ? 1 : -1)
        });
        this.handleOnChangePage(this.props.currentPartner.journalPage);
    }
    
    closeToast = () => {
        LocalStorage.saveData("showReleaseNote", "false");
    }

    displayMsg = () => {
       let showReleaseNote=  LocalStorage.getData("showReleaseNote");
       if(showReleaseNote !== "false"){
        toast( <div>
            <ReleaseNotes />
            <button onClick={this.closeToast}>Dont show again</button>
          </div> ,  {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 30000
        
      }) 
    }
       
      }
    render() {
        return (
            <React.Fragment>
                {(LocalStorage.getData("role") == "Admin") ?
                    <div className="link-back-block">
                        <span className="new-user-link" onClick={this.backToAllPartner.bind(this)}>Back to Partners</span>
                    </div>
                    :
                    <React.Fragment></React.Fragment>
                }
                {this.state.loadingData ? <TitledSpinner title='Loading...' outlined={false} />
                    :
                    <React.Fragment>
                        <div className="up-partner-block">
                            <div className="partner-info-block">
                                <div className="course-labels">
                                    <span className="partner-name"><b>{this.props.currentPartner.selectedPartner.providerName}</b></span>
                                    <div className="active-courses-block">
                                        <span className="active-course-count"><b>Active Course Count: </b></span>
                                        <span className={(this.props.currentPartner.selectedPartner.activeCourseCount == 0) ? "zero-count" : "number-courses"}><b>{this.props.currentPartner.selectedPartner.activeCourseCount + " Courses"}</b></span>
                                        <div className="exel-block">
                                            <span className="new-user-link" hidden={(this.props.currentPartner.selectedPartner.activeCourseCount == 0)} onClick={this.downloadExel.bind(this)}>Download Active Course List (Excel File)</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="course-buttons">
                                    <span className="action-label"><b>Action: </b></span>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="button-tooltip">
                                            Use this options to add new courses or to make changes to existing course information or replace thumbnail.
                                          </Tooltip>}>
                                        <Button variant="primary" className="add-courses-button" onClick={this.goToUpload.bind(this)}>
                                            Add New / Update Existing Courses
                                            <FontAwesomeIcon size="1x" className="tool-tip" icon={faInfoCircle} />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip id="button-tooltip">
                                            Use this option to schedule retirement of existing courses.
                                          </Tooltip>}>
                                        <Button variant="outline-danger" className="retire-button" onClick={this.goToRetirement.bind(this)}>
                                            Request Retirement of Courses
                                            <FontAwesomeIcon size="1x" className="tool-tip" icon={faInfoCircle} />
                                        </Button>
                                    </OverlayTrigger>                                  
                                </div>
                            </div>
                        </div>
                        <div className="table-block-partner">
                            <Table responsive striped hover className="journal-table">
                                <thead>
                                    <tr>
                                        <th>Courses Synced <SortingComponent Click={this.HandleChangeCourses} SortingState={this.state.sortingStateCoursesSynced} /></th>
                                        <th>Action <SortingComponent Click={this.HandleChangeAction} SortingState={this.state.sortingStateAction} /></th>
                                        <th>Sync Date <SortingComponent Click={this.HandleChangeDate} SortingState={this.state.sortingStateDate} /></th>
                                        <th>Synced By <SortingComponent Click={this.HandleChangeSyncedBy} SortingState={this.state.sortingStateSyncedBy} /></th>
                                        <th>Synced Metadata</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderTableBody()}
                                </tbody>
                            </Table>
                            <PaginationWithOutPromise currentPageIndex={this.props.currentPartner.journalPage} listPages={this.props.currentPartner.journalTotalPages} handleChangePage={this.handleOnChangePage}></PaginationWithOutPromise>
                        </div>
                        <ToastContainer style={{ width: "60%", background: "transparent" }}   />
                        <React.Fragment>
                            <Modal show={this.props.currentPartner.getFileLoadState} >
                                <Modal.Body>
                                    <h1 className="modal-text-excel-file">Generating Excel File</h1>
                                    <div className="excel-file-loader">
                                        <Spinner animation="grow" />
                                        <Spinner animation="grow" />
                                        <Spinner animation="grow" />
                                        <Spinner animation="grow" />
                                        <Spinner animation="grow" />
                                        <Spinner animation="grow" />
                                        <Spinner animation="grow" />
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </React.Fragment>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

    handleOnChangePage = (newCurrentPage: number): any => {
        this.props.currentPartner.journalPage = newCurrentPage;
        if (newCurrentPage == this.props.currentPartner.journalTotalPages) {
            this.props.currentPartner.journal = this.props.currentPartner.journalAll.slice((newCurrentPage * COUNT_ON_PAGE) - COUNT_ON_PAGE, this.props.currentPartner.journalAll.length);
        }
        else {
            this.props.currentPartner.journal = this.props.currentPartner.journalAll.slice((newCurrentPage * COUNT_ON_PAGE) - COUNT_ON_PAGE, (newCurrentPage * COUNT_ON_PAGE));
        }
    }

    renderTableBody() {
        if (this.props.currentPartner.getJournal.length == 0)
            return (
                <React.Fragment>
                    <tr>
                        <td colSpan={4}>
                            <FontAwesomeIcon size="1x" className="warning-icon" icon={faExclamationTriangle} />
                            <span className="warning-text"><i>No sync activity to show yet. Please begin by adding new courses.</i></span>
                        </td>
                    </tr>
                </React.Fragment>
            );
        else return (
            this.props.currentPartner.getJournal.map(x =>
                <React.Fragment>
                    <tr>
                        <td className="color-td-item">
                            {x.count}
                        </td>
                        <td className="color-td-item">
                            {x.action}
                        </td>
                        <td className="color-td-item">
                            {this.fromNumberToNormalDate(x.syncDate.getMonth() + 1)}/{this.fromNumberToNormalDate(x.syncDate.getDate())}/{x.syncDate.getFullYear()} {this.fromNumberToNormalDate(x.syncDate.getHours())}:{this.fromNumberToNormalDate(x.syncDate.getMinutes())}
                        </td>
                        <td className="color-td-item">
                            {x.syncedBy}
                        </td>
                        <td className="color-td-item">
                            {
                                !x.syncMetadataURL? <span>Data unavailable</span> :<span className="new-user-link"  onClick={this.downloadExcelMetadataForSyncedCourses.bind(this, x.id)}>Export(excel)</span>
                            }
                        </td>
                    </tr>
                </React.Fragment>
            )
        );
    }

    fromNumberToNormalDate(number: number) {
        if (number.toString().length == 1) {
            return `0${number}`
        }
        else return number;
    }

    async downloadExcelMetadataForSyncedCourses(journalId: string) {
        try {
            this.props.currentPartner.setLoadFileTrue();
            let response = await ProviderHttpHelper.GetSyncedCoursesMetaDataPresignedURL(journalId);
            this.downloadSyncMetadataExcel(response);
            this.props.currentPartner.setLoadFileFalse();
        }
        catch (error) {
            this.props.currentPartner.setLoadFileFalse();
        }
    }
    downloadSyncMetadataExcel(syncMetadaPresingedURL: string){
        const link = document.createElement('a');
        link.href = syncMetadaPresingedURL;
        link.setAttribute('download', 'SyncedCourses.xlsx');
        document.body.appendChild(link);
        link.click();
    }
}