import { observable, action } from 'mobx';
import SimpleMessageModalModel from '../Models/SimpleModalMessageModel';

export default class FullTextShowStore {
    @observable ShowingMessage: SimpleMessageModalModel = observable ({
        Title: "", Message: "", onHide: ()=>{}
    })

    @observable Show: boolean = false;
    @action 
    public showFullText = (title:string,message:string,onModalMessageHide: () => void = () => {}) => {
        if(this.Show==false) {
            this.Show = true;
            this.ShowingMessage = new SimpleMessageModalModel(title,message,onModalMessageHide);
        }
    }
}