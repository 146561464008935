import React, { Component } from 'react';
import './App.css';
import { RouterStore } from 'mobx-react-router';
import { Switch, Route, Redirect } from 'react-router';
import ReportComponent from './Report/Components/ReportComponent';
import ProviderComponent from './Providers/Components/Providers';
import UploadComponent from './Upload/Components/UploadComponent';
import ProgressComponent from './Progress/Components/ProgressComponent';
import ErrorModalComponent from './Common/Components/ErrorMessageModalComponent/ErrorMessageModalComponent';
import LoaderModalComponent from './Common/Components/LoaderModalComponent/LoaderModalComponent';
import WarningMessageModalComponent from './Common/Components/WarningMessageModalComponent/WarningMessageModalComponent';
import FullTextShowModalComponent from './Common/Components/FullTextShowModal/FullTextShowModal';
import Header from './Layout/Components/Header';
import ManageAccount from './ManageAccounts/Components/ManageAccount';
import CreateUser from './CreateUser/Components/CreateUser';
import EditUser from './EditUser/Components/EditUser';
import CurrentPartner from './CurrentPartner/Components/CurrentPartner';
import UploadCourses from './CurrentPartner/Components/UploadCourses';
import SyncingComponent from './Syncing/Components/SyncingComponent';
import Retirement from './Retirement/Components/Retirement';
import RetirementReport from './Retirement/Components/Report/RetirementReport';
import SyncingRetirementComponent from './Retirement/Components/Sync/SyncingRetirementComponent';


interface AppProps {
  routing?: RouterStore
}

export default class App extends Component<AppProps> {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className='container border'>
          <WarningMessageModalComponent />
          <ErrorModalComponent />
          <LoaderModalComponent />
          <FullTextShowModalComponent />
          <Switch>
            <Route path='/report' component={ReportComponent}></Route>
            <Route path='/upload' component={UploadComponent}></Route>
            <Route path='/progress' component={ProgressComponent}></Route>
            <Route path='/manage-accounts' component={ManageAccount}></Route>
            <Route path='/create-user' component={CreateUser}></Route>
            <Route path='/edit-user' component={EditUser}></Route>
            <Route path='/partner/upload' component={UploadCourses}></Route>
            <Route path='/partner/retirement' component={Retirement}></Route>
            <Route path='/retirement/report' component={RetirementReport}></Route>
            <Route path='/retirement/syncing' component={SyncingRetirementComponent}></Route>
            <Route path='/partner/update' exact>
              <Redirect to="/"/>
            </Route>
            <Route path='/syncing' component={SyncingComponent}></Route>
            <Route path='/partner' component={CurrentPartner}></Route>
            <Route path='/' component={ProviderComponent}></Route>
          </Switch>
        </div >
      </React.Fragment>
    )
  }
}

