import * as React from 'react'
import { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import { Redirect } from 'react-router';
import RetirementStore from '../../Store/RetirementStore';
import CurrentPartnerStore from '../../../CurrentPartner/Store/CurrentPartnerStore';
import ErrorMessageStore from '../../../Common/Stores/ErrorMessageStore';
import ProgressStore from '../../../Progress/Stores/ProgressStore';
import LoaderStore from '../../../Common/Stores/LoaderStore';
import UploadHttpHelper from '../../../Upload/HttpHelpers/UploadHttpHelper';
import DropZoneComponent from '../../../Common/Components/DropZone/DropZone';
import RetirementDropZone from './RetirementDropZone';
import { FaThumbsDown } from 'react-icons/fa';
import ReportStore from '../../../Report/Stores/ReportStore';

interface UploadComponentProps {
    routing?: RouterStore
    currentPartner?: CurrentPartnerStore
    errorMessage?: ErrorMessageStore
    progress?: ProgressStore
    loader?: LoaderStore
    retirementStore?: RetirementStore
    report?: ReportStore
}

@inject('routing', 'currentPartner', 'errorMessage', 'progress', 'loader', 'retirementStore' , 'report')
@observer
export default class UploadRetirement extends Component<UploadComponentProps> {

    fileHandler = (file: File) => {
        if (this.props.retirementStore.getSelectedDateIndex != -1) {
            this.props.retirementStore.setDateTypeError(false);
            let acceptedTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

            if ((file === undefined) || (acceptedTypes.findIndex(x => x == file.type) == -1)) this.props.retirementStore.setFileTypeError(true);
            else {
                this.props.retirementStore.setFileTypeError(false);
                this.props.errorMessage.showsErrors(UploadHttpHelper.SentExcelFile(file, this.props.currentPartner.selectedPartner.providerId, this.props.retirementStore.getAllRetirementsDate[this.props.retirementStore.getSelectedDateIndex])
                .then(response =>  response.json())
                .then((filePreSignedUrl) => {
                 this.props.report.setFilePreSignedUrl(filePreSignedUrl);
                    this.props.routing.push('/retirement/report');
                }), this.onErrorMessageHide);
                this.props.progress.setCanUserCloseWindow(false);
                this.props.loader.showLoading("Sending file...", this.onLoaderHide);
            }
        }
        else {
            this.props.retirementStore.setDateTypeError(true);
        }
    }

    onLoaderHide = () => {
        this.props.routing.push('/progress');
    }

    onErrorMessageHide = () => {
        this.props.routing.push('/partner/retirement');
    }

    render() {
        return (
            <div><RetirementDropZone onDrop={this.fileHandler}></RetirementDropZone></div>
        )
    }
}