import * as React from 'react'
import { Component } from 'react';
import { SortingState } from './SortState';
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

interface SortingComponentProps {
    SortingState: SortingState
    Click: Function
}

export default class SortingComponent extends Component<SortingComponentProps> {
    render() {
        let size = "1.5em";
        let renderedState;
        if (this.props.SortingState === SortingState.Down) {
            renderedState = <FaSortDown size={size} onClick={() => this.onClick(SortingState.Up)} />
        } else if (this.props.SortingState === SortingState.Up) {
            renderedState = <FaSortUp size={size} onClick={() => this.onClick(SortingState.Down)} />
        }
        else {
            renderedState = <FaSort size={size} onClick={() => this.onClick(SortingState.Down)} />
        }
        return <div style={{ display: "inline-block", cursor:"pointer" }}>
            {renderedState}
        </div>
    }

    onClick(newState: SortingState) {
        this.props.Click(newState);
    }
}