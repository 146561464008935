
import * as React from 'react'
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import SuccessComponent from '../SubElements/Success/Components/SuccessComponent';
import ErrorWarningComponent from '../SubElements/ErrorWarnings/Components/ErrorWarningComponent';
import './ReportComponent.css'
import ReportStore from '../Stores/ReportStore';
import ProviderStore from '../../Providers/Stores/ProviderStore';
import { Redirect } from 'react-router';
import ReportPage from '../Models/ReportPage';
import ErrorMessageStore from '../../Common/Stores/ErrorMessageStore';
import SyncStatus from '../SubElements/Success/Models/SyncStatus';
import ProgressStore from '../../Progress/Stores/ProgressStore';
import LoaderStore from '../../Common/Stores/LoaderStore';
import CurrentPartnerStore from '../../CurrentPartner/Store/CurrentPartnerStore';
import ErrorWarning from '../Models/ErrorWarningEnum';
import { Button, OverlayTrigger, Tooltip, Table, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import DownloadHttpHelper from '../HttpHelpers/DownloadHttpHelpr';
import FullTextShowStore from '../../Common/Stores/FullTextShowStore';
import { throws } from 'assert';
import ProviderStatus from '../../Providers/Models/ProviderStatus';
import { FaThemeisle } from 'react-icons/fa';
import CourseHttpHelper from '../HttpHelpers/CourseHttpHelper';
import SyncCoursesHttpHelper from '../HttpHelpers/SyncCoursesHttpHelper';

interface ReportComponentProps {
    routing?: RouterStore
    report?: ReportStore
    currentPartner?: CurrentPartnerStore
    errorMessage?: ErrorMessageStore
    progress?: ProgressStore
    loader?: LoaderStore
    textShow?: FullTextShowStore
}

interface ReportComponentState {
    successLoaded: boolean,
    errorwarningLoaded: boolean,
    currentPage: ReportPage
}

const charsCount = 100;

@inject('routing', 'report', 'currentPartner', 'errorMessage', 'progress', 'loader', 'textShow')
@observer
export default class ReportComponent extends Component<ReportComponentProps, ReportComponentState> {
    state = {
        successLoaded: false,
        errorwarningLoaded: false,
        currentPage: ReportPage.Success
    };

    changeCurrentPage = (newPage: ReportPage) => {
        this.setState({
            currentPage: newPage
        })
    }

    componentDidMount() {
        if (this.props.currentPartner != null) {
            this.props.loader.showLoading('Loading data...', () => { });
        }
        if (this.props.currentPartner.selectedPartner != null) {
            this.props.report.loadSuccess(this.props.currentPartner.selectedPartner.providerId).then(() => {
                this.setState({ currentPage: this.props.report.Success.items.length > 0 ? ReportPage.Success : ReportPage.ErrorWarning, successLoaded: true })
            });
            this.props.report.loadWarningError(this.props.currentPartner.selectedPartner.providerId).then(() => {
                this.setState({ currentPage: this.props.report.ErrorWarnings.items.length > 0 ? ReportPage.ErrorWarning : ReportPage.Success, errorwarningLoaded: true })
            });
            this.props.report.SyncSuccess = SyncStatus.NotSynced;
        }
    }

    backToAllPartner = (): void => {
        this.props.routing!.replace('/partner/upload');
        if (this.props.report.SyncSuccess != SyncStatus.Synced)
            this.props.currentPartner.selectedPartner.providerStatus = ProviderStatus.Unsynced;
        else
            this.props.currentPartner.selectedPartner.providerStatus = ProviderStatus.Free;
    }

    private DownloadCsvClickHandler = () => {
        this.props.errorMessage.showsErrors(DownloadHttpHelper.GetFile(true, this.props.currentPartner.selectedPartner.providerId).then((response) => {
            if (response == null) {
                return;
            }
            let csvUrl = window.URL.createObjectURL(response);
            let tempLink = document.createElement('a');
            tempLink.href = csvUrl;
            tempLink.setAttribute('download', 'SuccessTable.xlsx');
            tempLink.click();
        }));
    }

    render() {
        let isAllDataAlreadyLoaded = this.state.successLoaded && this.state.errorwarningLoaded;
        if (isAllDataAlreadyLoaded) {
            this.props.loader.hideLoading();
        }
        return (
            //  (this.props.currentPartner.selectedPartner == null) ? <Redirect to='/' /> :
            //     <div className='table-handler-container'>
            //         <div className='row'>
            //             <h2 className='w-25'>Results</h2>
            //             <div className='w-75 row justify-content-end px-0'>
            //                 <button className="btn ripple report-page-btn m-1" onClick={() => { this.props.routing.push('/') }}>Back to providers</button>
            //                 <button className="btn ripple report-page-btn m-1" onClick={() => { this.props.routing.push('/upload') }}>Back to upload</button>
            //             </div>
            //         </div>
            //         <ul className='nav nav-tabs nav-justified'>
            //             <li className='nav-item' hidden={this.props.report.Success.items.length < 1}>
            //                 <a className={'nav-link ' + (this.state.currentPage == ReportPage.Success ? 'active' : '')} onClick={() => this.changeCurrentPage(ReportPage.Success)}>Success courses</a>
            //             </li>
            //             <li className='nav-item' hidden={this.props.report.ErrorWarnings.items.length < 1}>
            //                 <a className={'nav-link ' + (this.state.currentPage == ReportPage.ErrorWarning ? 'active' : '')} onClick={() => this.changeCurrentPage(ReportPage.ErrorWarning)}>Errors and warnings</a>
            //             </li>
            //         </ul>
            //         {isAllDataAlreadyLoaded ? <div className='tab-content' id='nav-tabContent'>
            //             <div hidden={this.state.currentPage != ReportPage.Success}>
            //                 <SuccessComponent />
            //             </div>
            //             <div hidden={this.state.currentPage != ReportPage.ErrorWarning}>
            //                 <ErrorWarningComponent />
            //             </div>
            //         </div> : ''}
            //     </div >

            <React.Fragment>
                <div>
                    <div className="link-back-block">
                        <span className="new-user-link margin-link" onClick={this.backToAllPartner.bind(this)}>Back to Add New Courses</span>
                        <span className="back-description">(Save Upload without Syncing)</span>
                    </div>
                    <h2 className='w-25'>Results</h2>
                </div>
                {/* {isAllDataAlreadyLoaded ? */}
                <div className="count-display-block">
                    <div className="valid">
                        <p className="valid-courses-label">
                            Valid Courses
                        </p>
                        <p className="valid-count">
                            {this.props.report.Success.items.length - this.props.report.ErrorWarnings.items.filter(x => x.errorWarning == ErrorWarning.Warning).length}
                        </p>
                    </div>
                    <div className="warning">
                        <p className="warning-courses-label">
                            Valid With Warnings
                        </p>
                        <p className="warning-count">
                            {this.props.report.ErrorWarnings.items.filter(x => x.errorWarning == ErrorWarning.Warning).length}
                        </p>
                    </div>
                    <div className="errors">
                        <p className="error-courses-label">
                            Errors
                        </p>
                        <p className="error-count">
                            {this.props.report.ErrorWarnings.items.filter(x => x.errorWarning == ErrorWarning.Error).length}
                        </p>
                    </div>
                </div>
                <div className="error-right">
                    <label className='error-label'>{this.props.report.ErrorWarnings.items.filter(x => x.errorWarning == ErrorWarning.Error).length > 0 && '**Courses with Errors will not be added'}</label>
                </div>
                <div className="btn-block-sync">
                    <Button variant="secondary" className="download-result-btn" onClick={this.DownloadCsvClickHandler} hidden={this.props.report.SyncSuccess === SyncStatus.Synced}>Download Result (Excel File)</Button>
                    <div className="right-side-btn">
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Use this option to cancel the<br />
                                    current upload. You will be directed<br />
                                    back to the previous page where<br />
                                    you can re-upload the zip.
                            </Tooltip>}>
                            <Button variant="danger" className="discard-btn" onClick={this.showDiscardModal.bind(this)}>
                                Discard
                                <FontAwesomeIcon size="1x" className="tool-tip" icon={faInfoCircle} />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                                <Tooltip id="button-tooltip">
                                    Click on 'Sync Courses' button if you are satisfied with the results and want to add courses.<br />
                                Warning: Once you click on Sync button, your error log will be lost.
                            </Tooltip>}>
                            <Button variant="primary" className="sync-btn" hidden={this.props.report.SyncSuccess !== SyncStatus.NotSynced || this.props.report.Success.items.length == 0} onClick={this.showSyncModal.bind(this)}>
                                Sync Courses {'(' + this.props.report.Success.items.length + ')'}
                                <FontAwesomeIcon size="1x" className="tool-tip" icon={faInfoCircle} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                </div>
                <h2 className='w-25 result-margin'>Results {'(' + (this.props.report.Success.items.length + this.props.report.ErrorWarnings.items.filter(x => x.errorWarning == ErrorWarning.Error).length) + ')'}</h2>
                <Table responsive striped hover className="journal-table">
                    <thead>
                        <tr>
                            <th>Course Title</th>
                            <th className="course-id-th">Course ID</th>
                            <th>Status</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.mapAllCoursesToTable()}
                    </tbody>
                </Table>
                <React.Fragment>
                    <Modal show={this.props.report.getModalState} onHide={this.closeModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Message log</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.props.report.getText.map(x =>
                                <p>{x}</p>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.closeModal.bind(this)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </React.Fragment>
                <React.Fragment>
                    <Modal show={this.props.report.getSyncModalState} onHide={this.closeSyncModal.bind(this)}>
                        <Modal.Body>
                            <div className="icon-sync-modal">
                                <FontAwesomeIcon size="2x" className='modal-icon' icon={faExclamationTriangle} />
                            </div>
                            <div className="text-modal">
                                <span className="span-text-modal">
                                    Are you sure you want to sync this upload ?
                                    Courses with errors will not be uploaded
                                    and your error log will not be saved
                                </span>
                            </div>
                            <div className="btn-modal">
                                <Button variant="secondary" className="cancel-btn-sync-modal" onClick={this.closeSyncModal.bind(this)}>
                                    Cancel
                                </Button>
                                <Button variant="primary" className="yes-btn-sync-modal" onClick={this.onSyncing.bind(this, 0)}>
                                    Yes
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
                <React.Fragment>
                    <Modal show={this.props.report.getDiscardModalState} onHide={this.closeDiscardModal.bind(this)}>
                        <Modal.Body>
                            <div className="icon-sync-modal">
                                <FontAwesomeIcon size="2x" className='modal-icon' icon={faExclamationTriangle} />
                            </div>
                            <div className="text-modal">
                                <span className="span-text-modal">
                                    Are you sure you want to discard this upload ?
                                </span>
                            </div>
                            <div className="btn-modal">
                                <Button variant="secondary" className="cancel-btn-sync-modal" onClick={this.closeDiscardModal.bind(this)}>
                                    Cancel
                                </Button>
                                <Button variant="danger" className="yes-btn-sync-modal" onClick={this.discard.bind(this)}>
                                    Discard
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </React.Fragment>
                {/* : ''} */}
            </React.Fragment>
        );
    }

    onSyncing(counter: number = 0) {
        this.closeSyncModal();
        this.props.routing.push('/syncing');
        // this.props.loader.showLoading('Syncing courses...', () => { });
        this.props.errorMessage.showsErrors(SyncCoursesHttpHelper.SyncUploaded(this.props.currentPartner.selectedPartner.providerId,  this.props.report.filePreSignedUrl,
            this.props.currentPartner.updateOnlyMetadata)
            .then((x) => {
                if (counter == 3) {
                    this.props.loader.hideLoading();
                    this.props.report.SyncSuccess = SyncStatus.ErrorWhileSynced;
                    return;
                }
                if (!!x && x == "repeat") {
                    this.onSyncing(++counter);
                    return;
                } else {
                    this.props.currentPartner.setStatusFree();
                    this.OnSyncSuccessCourses();
                    return;
                }
            }).catch(() => {
                this.props.loader.hideLoading();
                this.props.report.SyncSuccess = SyncStatus.ErrorWhileSynced
            }));
    }

    private OnSyncSuccessCourses = () => {
        this.props.report.SyncSuccess = SyncStatus.Synced;
    }

    discard() {
        CourseHttpHelper.Discard(this.props.currentPartner.selectedPartner.providerId).then(x => {
            this.props.currentPartner.selectedPartner.providerStatus = ProviderStatus.Free;
            this.props.routing!.replace('/partner/upload');
            this.closeDiscardModal();
        });
    }

    getText() {
        return this.props.report.text.map(x =>
            <p>{x}</p>
        );
    }

    closeModal() {
        this.props.report.setModal(false);
    }

    closeSyncModal() {
        this.props.report.setSyncModal(false);
    }

    showModal() {
        this.props.report.setModal(true);
    }

    showSyncModal() {
        this.props.report.setSyncModal(true);
    }

    closeDiscardModal() {
        this.props.report.setDiscardModal(false);
    }

    showDiscardModal() {
        this.props.report.setDiscardModal(true);
    }

    mapAllCoursesToTable() {
        let trs = [];
        for (let i = 0; i < this.props.report.Success.items.length; i++) {
            trs.push(
                <React.Fragment>
                    <tr>
                        <td>
                            {this.props.report.Success.items[i].title}
                        </td>
                        <td>
                            {this.props.report.Success.items[i].courseID}
                        </td>
                        {
                            (this.props.report.ErrorWarnings.items.filter(x => x.courseID == this.props.report.Success.items[i].courseID).length > 0) ?
                                <React.Fragment>
                                    <td className="no-wrap-td">
                                        <span className="warning-color">Valid With Warning</span>
                                    </td>
                                    {/* <td>
                                        <span className="warning-message-table">
                                            {this.props.report.ErrorWarnings.items.filter(x => x.courseID == this.props.report.Success.items[i].courseID)[0].messageLog}
                                        </span>
                                    </td> */}
                                    {this.getFormattedTd('Message log', this.props.report.ErrorWarnings.items.filter(x => x.courseID == this.props.report.Success.items[i].courseID)[0].messageLog)}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <td className="no-wrap-td">
                                        <span className="valid-color">Valid</span>
                                    </td>
                                    <td>

                                    </td>
                                </React.Fragment>
                        }
                    </tr>
                </React.Fragment>
            )
        }
        for (let i = 0; i < this.props.report.ErrorWarnings.items.length; i++) {
            if (this.props.report.ErrorWarnings.items[i].errorWarning == ErrorWarning.Error) {
                trs.push(
                    <React.Fragment>
                        <tr>
                            <td>
                                {this.props.report.ErrorWarnings.items[i].title}
                            </td>
                            <td>
                                {this.props.report.ErrorWarnings.items[i].courseID}
                            </td>
                            <td className="no-wrap-td">
                                <span className="error-color"><i>Error</i></span>
                            </td>
                            {/* <td>
                                {this.props.report.ErrorWarnings.items[i].messageLog}
                            </td> */}
                            {this.getFormattedTd('Message log', this.props.report.ErrorWarnings.items.filter(x => x.courseID == this.props.report.ErrorWarnings.items[i].courseID)[0].messageLog)}
                        </tr>
                    </React.Fragment>
                );
            }
        }
        return trs;
    }

    private FormatWithTextCharsLimit(stringToFormat: string) {
        return stringToFormat.length <= charsCount ? stringToFormat : stringToFormat.substr(0, charsCount) + '...';
    }

    private getFormattedTd = (title: string, messageLog: string[]) => {
        let text = messageLog.toString();
        if (!text) {
            text = `${title} is not filled`;
        }
        let textLen = text.length;
        return <td
            className={textLen > 100 ? 'clickable-td' : ''}
            onClick={textLen > 100 ? this.getModalShowAllTextHandler.bind(this, messageLog) : () => { }}
        >
            {textLen > 100 ? this.FormatWithTextCharsLimit(text) : text}
        </td>
    }

    private getModalShowAllTextHandler = (text: string[]) => {
        this.props.report.setText(text);
        this.showModal();
    }
}