import { AppSettings } from '../../Settings/Settings';
import AuthRequestModel from '../Models/AuthRequestModel';
import RegisterRequestModel from '../Models/RegisterRequestModel';

export default class AuthHttpHelper {

    private static authControllerName: string = 'auth'

    static Authorize(username: string, password: string): Promise<any> {
        let loginMehodName = 'login';
        let params = {
            username: username,
            password: password
        }
        let url = `${AppSettings.BaseUrl}/${this.authControllerName}/${loginMehodName}`;
        return this.SendAuthRequest(url,params);
    }

    static Register(username: string, password: string, confirmpassword:string): Promise<any> {
        let registerMethodName = 'register';
        let params = {
            username: username,
            password: password,
            confirmpassword: confirmpassword            
        }
        let url = `${AppSettings.BaseUrl}/${this.authControllerName}/${registerMethodName}`;
        return this.SendAuthRequest(url,params);
    }

    private static SendAuthRequest(url: string, params: AuthRequestModel|RegisterRequestModel): Promise<Response|Promise<never>> {

        return fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params),
            method: 'POST',
        }).then((res) => {
            if(res.status === 400) {
                return res.json().then((x) => {
                    throw x;
                })
            } else {
                return res;
            }
        });
    }
}