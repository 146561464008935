import { AppSettings } from '../../Settings/Settings';
import BaseHttpHelper from '../../Common/HttpHelpers/BaseHttpHelper';

export default class UploadHttpHelper {

    static SentFile(file: File, providerId: string, updateOnlyMetadata: boolean = false): Promise<any> {
        var subDommen = 'upload/uploadZip'
        var url = `${AppSettings.BaseUrl}/${subDommen}`;
        var formData = new FormData();
        formData.append('Archive', file);
        formData.append('ProviderId', providerId);
        formData.append('mimeType', 'application/zip');
        formData.append('updateOnlyMetadata', updateOnlyMetadata.toString());
        return BaseHttpHelper.SendFilePostRequest(url, formData);
    }

    static SentExcelFile(file: File, providerId: string, date: Date): Promise<any> {
        var subDommen = 'upload/uploadRetirement'
        var url = `${AppSettings.BaseUrl}/${subDommen}`;
        var formData = new FormData();
        formData.append('Archive', file);
        formData.append('ProviderId', providerId);
        formData.append('ExpirationDate',(date.getTime() + 7200000).toString());
        return BaseHttpHelper.SendFilePostRequest(url, formData)
    }
}