enum StatusProcessing {
    StartProcessing,
    UploadZip,
    UnzipArchive,
    ParseExcelMetadata,
    ParseExcelRetired,
    ParseExcelTopCources,
    EndProcessing,
    EndWithError,
    Synced,
    StartRetired,
    EndRetired
}

export default StatusProcessing