
import * as React from 'react'
import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import AuthHttpHelper from '../HttpHelpers/AuthHttpHelper';
import './AuthComponent.css'
import LocalStorage from '../../Common/Services/LocalStorage';
import ErrorMessageStore from '../../Common/Stores/ErrorMessageStore';
import logo from '../../Cornerstone_Logo.png';
import HeaderStore from '../../Layout/Stores/HeaderStore';
import {AppSettings} from "../../Settings/Settings";

interface AuthComponentProps {
    routing: RouterStore,
    errorMessage: ErrorMessageStore,
    header?: HeaderStore
}

interface AuthComponentState {
    AuthErrorMessage: string
}

@inject('routing', 'errorMessage', 'header')
@observer
export default class AuthComponent extends Component<AuthComponentProps, AuthComponentState> {
    constructor(props: AuthComponentProps) {
        super(props);
        this.state = {
            AuthErrorMessage: null
        }
    }
    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let data = new FormData(event.target as HTMLFormElement);
        AuthHttpHelper.Authorize(data.get('login').toString(), data.get('password').toString())
            .then((res) => {
                res.text().then((text: string) => {
                    var path = '/';
                    LocalStorage.saveToken(text);
                    var base64Url = text.split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));
                    var model = JSON.parse(jsonPayload);
                    var groups = model["cognito:groups"];
                    try {
                        if ((groups as string[]).indexOf("Admin") != -1) {
                            LocalStorage.saveRole("Admin");
                        }
                        else {
                            LocalStorage.saveRole("");
                            path = '/partner';
                        }
                    }
                    catch (e) {
                        LocalStorage.saveRole("");
                        path = '/partner';
                    }
                    this.props.header.checkIfUserIsAdmin();
                    this.props.header.setSelected(1);
                    this.props.routing.replace(path);
                })
            }).catch((err) => {
                this.setState({ AuthErrorMessage: err.message });
            });
    }
    componentDidMount() {
    }

    deleteErrorState() {
        this.setState({
            AuthErrorMessage: null
        });
    }

    render() {
        const urlHashParam = this.props.routing.location.hash;           
        return (
            <div className='aligner'>
                {AppSettings.Environment === "prod" && <img src={logo} className='logo-corner'></img>}
                {AppSettings.Environment !== "prod" && 
                    <div>
                        <span className ="textLogoEnv">
                            {AppSettings.Environment}
                        </span>
                    </div>
                }
                <p className='description-text'>Content Upload Tool</p>
                {urlHashParam === "#provider_err" && 
                    <p className='error-message-label'> 
                        {LocalStorage.getData('loadProviderErrorMsg')}
                        <button className="error-message-close-btn" onClick={() => this.props.routing.replace('/auth')}>X</button>
                    </p>
                }
                <form className='login-window' onSubmit={this.handleSubmit}>
                    <label className='error-message-label'>{this.state.AuthErrorMessage}</label>
                    <input type='text' placeholder='Email Address' name='login' onChange={this.deleteErrorState.bind(this)}></input>
                    <input type='password' placeholder='Password' name='password' onChange={this.deleteErrorState.bind(this)}></input>
                    <input type='submit' className='btn ripple btn-margin' value='Login' ></input>
                </form>
                <p className='email-contact'>If you are having issues loggin in, please<br />
                 contact us at <a href='mailto:contentupdates@csod.com' className='email-link'>contentupdates@csod.com</a></p>
            </div>
        )
    }
}