import { Modal, Button } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import React ,{ Component } from 'react';
import WarningMessageStore from '../../Stores/WarningMessageStore';
interface ErrorModalProps {
    warningMessage?: WarningMessageStore
}

@inject('warningMessage')
@observer
export default class WarningMessageModalComponent extends Component<ErrorModalProps> {

    render() {
        return <Modal
            show={this.props.warningMessage.Show}
            onHide={this.handleOnCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {this.props.warningMessage.WarningMessage.Title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {this.props.warningMessage.WarningMessage.Message}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button className = 'w-50' variant = 'primary' onClick={this.handleOnCancel}>Cancel</Button>
                <Button className = 'w-50' variant = 'primary' onClick={this.handleOnOk}>Ok</Button>
            </Modal.Footer>
    </Modal>
  }

  private handleOnCancel = () => {
    this.handleOnButtonClick();
    this.props.warningMessage.WarningMessage.onCancel();
  }
  private handleOnOk = () => {
    this.handleOnButtonClick();
    this.props.warningMessage.WarningMessage.onOk();
  }
  private handleOnButtonClick = () => {
    this.props.warningMessage.Show = false;
  }
}