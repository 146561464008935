import { AppSettings } from '../../Settings/Settings';
import LocalStorage from '../../Common/Services/LocalStorage';
import Authorization from '../../Authorization/Services/AuthorizationService';

export default class SyncCoursesHttpHelper {

    static SyncUploaded(providerId: string , filePreSignedUrl: string, updateOnlyMetadata: boolean = false): Promise<any> {
        var providers = 'sync'
        var url = `${AppSettings.BaseUrl}/${providers}/syncUploaded`;
        let params = providerId ? `?${new URLSearchParams({providerId:providerId, action:localStorage.getItem('action'), updateOnlyMetadata: updateOnlyMetadata.toString()}).toString()}` : '';
        return fetch(`${url}${params}`, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer '+LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(filePreSignedUrl),
        }).then(response=> {
            if (response.status === 401) {
                Authorization.redirectUrl();
                return null;
            } else if(response.status === 400){
                return response.json().then(x => {
                    throw x;
                })
            }
        }).catch(x=>{
            if(x.message == "The operation was canceled."){
                return "repeat"
            }
            if (x.message) {
                throw x;
            }
            
        })
    }

    static SyncUploadedRetirement(providerId: string , filePreSignedUrl: string): Promise<any> {
        var providers = 'sync'
        var url = `${AppSettings.BaseUrl}/${providers}/syncUploadedRetirement`;
        let params = providerId ? `?${new URLSearchParams({providerId:providerId}).toString()}` : '';
        return fetch(`${url}${params}`, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer '+LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(filePreSignedUrl),
        }).then(response=> {
            if (response.status === 401) {
                Authorization.redirectUrl();
                return null;
            } else if(response.status === 400){
                return response.json().then(x => {
                    throw x;
                })
            }
        }).catch(x=>{
            if(x.message == "The operation was canceled."){
                return "repeat"
            }
            if (x.message) {
                throw x;
            }
            
        })
    }
}