import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom'
import { inject, observer } from "mobx-react";
import { Navbar, Nav, Form, Button, FormControlProps } from "react-bootstrap";
import logo from "../../Cornerstone_Logo.png";
import "./CreateUser.css";
import { RouterStore } from "mobx-react-router";
import ManageAccountStore from "../../ManageAccounts/Stores/ManageAccountStore";

interface ManageAccountProps {
    manageAccount?: ManageAccountStore,
    routing?: RouterStore
}

@inject('manageAccount', 'routing')
@observer
export default class CreateUser extends Component<ManageAccountProps> {

    backToUsers = (): void => {
        this.props.routing!.replace('/manage-accounts');
        this.props.manageAccount.setAllNullOnCreateUser();
    }

    onUserNameChange(e: any) {
        this.props.manageAccount.setUserName(e.target.value);
    }

    onPartnerChange(event: any) {
        this.props.manageAccount.setPartner(event.target.value);
    }

    onPasswordChange(e: any) {
        this.props.manageAccount.setPassword(e.target.value);
    }

    onRePasswordChange(e: any) {
        this.props.manageAccount.setRePassword(e.target.value);
    }

    registerUser() {
        this.props.manageAccount.RegisterUser();
    }

    render() {
        return (
            <Fragment>
                <div className="link-new-user-block">
                    <span className="new-user-link" onClick={this.backToUsers.bind(this)}>Back to Users</span>
                </div>
                <div className='center-block'>
                    <div className='main-descr'><h2>Create New User</h2></div>
                    <div>
                        <p className="layer-name"><b>Partner Name</b></p>
                        <Form.Control type="text" placeholder="Partner Name" onChange={this.onPartnerChange.bind(this)} value={this.props.manageAccount.CreateUser.partner} />
                    </div>
                    <div>
                        <p className="layer-name"><b>Username</b></p>
                        <Form.Control type="email" placeholder="Username" onChange={this.onUserNameChange.bind(this)} value={this.props.manageAccount.CreateUser.username} />
                        <p className="desc-text first-line"><i>Format: partner@cornerstonepartner.com</i></p>
                        <p className="desc-text"><i>Example: CyberU@cornerstonepartner.com</i></p>
                    </div>
                    <div>
                        <p className="layer-name"><b>Password</b></p>
                        <Form.Control type="password" placeholder="Password" onChange={this.onPasswordChange.bind(this)} value={this.props.manageAccount.CreateUser.password} />
                    </div>
                    <div>
                        <p className="layer-name"><b>Re-Type Password</b></p>
                        <Form.Control type="password" placeholder="Re-Type Password" onChange={this.onRePasswordChange.bind(this)} value={this.props.manageAccount.RePassword} />
                        <p className="desc-text first-line"><i>Must have 1 uppercase character, 1 lowercase</i></p>
                        <p className="desc-text"><i>character with a minimum for 6 characters</i></p>
                    </div>
                    <div className="btn-block">
                        <Button variant="primary" className="save-user-button" onClick={this.registerUser.bind(this)}>Save</Button>
                    </div>
                </div>
                <div className="text-error-block">
                    <label className='error-label'>{this.props.manageAccount.getError}</label>
                    <label className='success-label'>{this.props.manageAccount.getSuccessMessage}</label>
                </div>
            </Fragment>
        )
    }
}