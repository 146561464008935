import { observable, action } from 'mobx';
import LocalStorage from '../../Common/Services/LocalStorage';
import jwt from 'jwt-decode';

class HeaderStore {

    constructor() {
        this.set();
        this.checkIfUserIsAdmin();
    }

    @observable selected: number = 1;
    @observable isShowLeftMenu = false;
    @observable isAdmin : boolean;

    @action setSelected(n: number) {
        this.selected = n;
    }

    @action setMenuState() {
        this.isShowLeftMenu = !this.isShowLeftMenu;
    }

    @action logOut() {
        LocalStorage.saveToken('');
    }

    @action set() {
        let current = window.location.toString();
        if (current.search('manage-accounts') != -1) this.selected = 2;
        if (current.search('create-user') != -1) this.selected = 2;
        if (current.search('edit-user') != -1) this.selected = 2;
    }

    @action checkIfUserIsAdmin(){
        if(LocalStorage.getData("token")){
            const user = jwt<any>(LocalStorage.getData("token"));
            if(user["cognito:groups"]){
                this.isAdmin = user["cognito:groups"].includes('Admin');   
            }
        }else{
            this.isAdmin = false;
        }
        
    }
    
}

export default HeaderStore;