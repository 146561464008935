import { Modal, Button, Spinner } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import React ,{ Component } from 'react';
import ProgressStore from '../../../Progress/Stores/ProgressStore';
import "./Loader.css"
import LoaderStore from '../../Stores/LoaderStore';
import TitledSpinner from '../TitledSpinner/TitledSpinner'

interface LoaderModalProps {
    progress?: ProgressStore
    loader?: LoaderStore
}

@inject('progress','loader')
@observer
export default class LoaderModalComponent extends Component<LoaderModalProps> {

    render() {
        return <Modal
            aria-labelledby="contained-modal-title-vcenter"
            show={this.props.loader.isLoading}
            onHide={this.onHide}
            centered
        >
            <Modal.Body>
                <TitledSpinner title={this.props.loader.Title} outlined = {true}/>
            </Modal.Body>
        </Modal>
    }

    onHide = () => {
        this.props.loader.onCloseLoader();
    }
}