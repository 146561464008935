import { observable, action } from 'mobx';

export default class LoaderStore {
    @observable Title: string = "Loading..."
    @observable onCloseLoader: () => void = () => {}
    @observable isLoading: boolean = false;

    @action
    public showLoading(title: string, onCloseLoader: ()=>void) {
        this.isLoading = true;
        this.onCloseLoader = onCloseLoader;
        this.Title = title;
    }

    @action
    public showLoadingWithOutFunc(title: string) {
        this.Title = title;
        this.isLoading = true;
    }

    @action
    public hideLoading = () => {
        this.isLoading = false;
        this.onCloseLoader();
    }
}