import { observable, action, computed } from "mobx";
import UserModel from "../Models/UserModel";
import ManageAccountHttpHelper from "../HttpHelpers/ManageAccountHttpHelper";
import CreateUserModel from "../../CreateUser/Models/CreateUserModel";
import CreateUserHttpHelper from "../../CreateUser/HttpHelpers/CreateUserHttpHelper";
import NewAttributeModel from "../../EditUser/Models/NewAttributeModel";
import NewPasswordModel from "../../EditUser/Models/NewPasswordModel";
import EditUserHttpHelper from "../../EditUser/HttpHelpers/EditUserHttpHelper";
import PagingModel from "../../Common/Models/PagingModel";

const COUNT_ON_PAGE: number = 20;

class ManageAccountStore {
    @observable Users: PagingModel<UserModel> = observable({ totalPages: 0, currentPage: 0, items: [] });

    @observable LoadedUsers: UserModel[] = observable([]);
    //@observable Users: UserModel[] = observable([]);
    @observable CreateUser: CreateUserModel = observable({
        username: '',
        password: '',
        partner: ''
    });
    @observable RePassword: string = '';
    @observable status: string = '';
    @observable isStatus: boolean = false;
    @observable search: string = '';
    @observable errorCreatingUser: string = null;
    @observable successMessage: string = null;

    @observable newUserAttributes: NewAttributeModel = observable({
        username: '',
        partner: '',
        email: '',
        oldEmail: ''
    });

    @observable newUserPassword: NewPasswordModel = observable({
        username: '',
        email: '',
        password: '',
        rePassword: ''
    });

    @observable errorNewAttribute: string = null;
    @observable errorNewPassword: string = null;
    @observable successNewAttribute: string = null;
    @observable successNewPassword: string = null;

    @action loadUsers = () => {
        return ManageAccountHttpHelper.GetUsers().then((res: UserModel[]) => {
            this.LoadedUsers = res;
            this.Users.currentPage = 1;
            let total = this.LoadedUsers.length/COUNT_ON_PAGE;
            if (this.LoadedUsers.length%COUNT_ON_PAGE!=0) total++;
            this.Users.totalPages = Math.floor(total);
            if (this.Users.totalPages <=1) this.Users.items = this.LoadedUsers;
            else this.Users.items = this.LoadedUsers.slice(0,20);
        });
    }

    @action setAllNullOnCreateUser(){
        this.errorCreatingUser = null;
        this.successMessage = null;
    }

    @action setUserName(name: string) {
        this.CreateUser.username = name;
        this.errorCreatingUser = null;
        this.successMessage = null;
    }

    @action setPartner(name: string) {
        this.CreateUser.partner = name;
        this.errorCreatingUser = null;
        this.successMessage = null;
    }

    @action setPassword(name: string) {
        this.CreateUser.password = name;
        this.errorCreatingUser = null;
        this.successMessage = null;
    }

    @action setRePassword(name: string) {
        this.RePassword = name;
        this.errorCreatingUser = null;
        this.successMessage = null;
    }

    @action RegisterUser() {
        if (this.CreateUser.password != this.RePassword) {
            this.errorCreatingUser = 'Re-type password does not match password';
        }
        else CreateUserHttpHelper.CreateUser(this.CreateUser).then(e => {
            this.successMessage = 'User account has been successfully created';
            this.CreateUser.partner = '';
            this.CreateUser.password = '';
            this.CreateUser.username = '';
            this.RePassword = '';
        }).catch(err => {
            this.errorCreatingUser = err.message;
            console.log(err);
        });
    }

    @computed get getError() {
        return this.errorCreatingUser;
    }

    @computed get getSuccessMessage() {
        return this.successMessage;
    }

    @action setEditedUser(p: string, u: string, e: string) {
        this.newUserAttributes.email = e;
        this.newUserAttributes.partner = p;
        this.newUserAttributes.username = u;
        this.newUserPassword.email = e;
        this.newUserPassword.username = u;
        this.newUserAttributes.oldEmail = e;
    }

    @action setSearch(value: string) {
        this.search = value;
        if (this.search == '') {
            this.Users.items = this.LoadedUsers;
        }
        else {
            this.Users.items = this.LoadedUsers.filter(x => this.filter(x, value));
        }
    }

    filter(value: UserModel, search: string) {
        return value.username.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    }

    @action setNewPartner(p: string) {
        this.newUserAttributes.partner = p;
        this.successNewAttribute = null;
        this.errorNewAttribute = null;
    }

    @action setNewEmail(e: string) {
        this.newUserAttributes.email = e;
        this.successNewAttribute = null;
        this.errorNewAttribute = null;
    }

    @action setNewPassword(p: string) {
        this.newUserPassword.password = p;
        this.errorNewPassword = null;
        this.successNewPassword = null;
    }

    @action setNewRePassword(p: string) {
        this.newUserPassword.rePassword = p;
        this.errorNewPassword = null;
        this.successNewPassword = null;
    }

    @action setNewAttribute() {
        return EditUserHttpHelper.EditAttribute(this.newUserAttributes).then(e => {
            this.successNewAttribute = "Data has been modified successfully";
        }).catch(err => {
            this.errorNewAttribute = err.message;
            console.log(err);
        });
    }

    @action setNewUserPassword() {
        if (this.newUserPassword.password != this.newUserPassword.rePassword) {
            this.errorNewPassword = 'Re-type password does not match password';
        }
        else EditUserHttpHelper.EditPassword(this.newUserPassword).then(e => {
            this.successNewPassword = "Password has been modified successfully";
        }).catch(err => {
            this.errorNewPassword = err.message;
            console.log(err);
        });
    }

    @computed get getSuccessAttMessage(){
        return this.successNewAttribute;
    }

    @computed get getSuccessPassMessage(){
        return this.successNewPassword;
    }

    @computed get getErrorAttMesage(){
        return this.errorNewAttribute;
    }

    @computed get getErrorPassMesage(){
        return this.errorNewPassword;
    }

    @action setAllNullOnEditUser(){
        this.newUserAttributes.email = '';
        this.newUserAttributes.partner = '';
        this.newUserPassword.password = '';
        this.newUserPassword.rePassword = '';
        this.errorNewAttribute = null;
        this.errorNewPassword = null;
        this.successNewAttribute = null;
        this.successNewPassword = null;
    }
}

export default ManageAccountStore;