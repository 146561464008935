export default class LocalStorage {
    private static TOKEN: string = 'token';

    static saveToken(token: string) {
        localStorage.setItem(this.TOKEN, token);
    }

    static saveRole(role: string) {
        localStorage.setItem('role', role);
    }

    static getToken(): String | null {
        let token = localStorage.getItem(this.TOKEN);
        return token ? token : null
    }

    static saveData(name: string, value: string) {
        localStorage.setItem(name, value);
    }

    static getData(name: string) {
        let value = localStorage.getItem(name)
        return value;
    }
}