import Authorization from '../../Authorization/Services/AuthorizationService';
import LocalStorage from '../Services/LocalStorage';
import { JsonHubProtocol } from '@aspnet/signalr';

export default class BaseHttpHelper {
    static ThenHandler<T>(response: Response): Promise<T> {
        if (response.status === 401) {
            Authorization.redirectUrl();
        } else {
            return response.json().then(x => {
                if (x.message) {
                    throw x;
                }
                return <T>x
            });
        }
    }

    static DownloadFile(url: string){
        return fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer ' + LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            }
        }).then((response)=> {
            if (response.status === 401) {
                Authorization.redirectUrl();
            } else {
                return response;
            }
        });
    }

    static SendGetRequest<T>(url: string, params: any = null): Promise<T> {
        params = params ? `?${new URLSearchParams(params).toString()}` : '';
        return fetch(`${url}${params}`, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer ' + LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            method: 'Get',
        }).then((response) => BaseHttpHelper.ThenHandler<T>(response));
    }

    static SendPostRequest<T>(url: string, params: any = null, body: any): Promise<T> {
        params = params ? `?${new URLSearchParams(params).toString()}` : '';
        return fetch(`${url}${params}`, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer ' + LocalStorage.getToken().toString() : '',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
            method: 'POST',
        }).then();
    }

    static SendFilePostRequest(url: string, FormData: FormData): Promise<any> {
        return fetch(url, {
            headers: {
                'Authorization': LocalStorage.getToken() ? 'Bearer ' + LocalStorage.getToken().toString() : '',
            },
            body: FormData,
            method: 'POST',
        }).then((response): any  => {
            if (response.status === 401) {
                Authorization.redirectUrl();
            } else if (response.status === 200) {
                return response;
            } else if (response.status === 400) {
                return response.json().then(x => {
                    if (x.message) {
                        throw x;
                    }
                });
            }
        }).catch(x => {
            // if(x.message == "Failed to fetch"){
            //     throw new Error("Something went wrong. Try again later.");
            // }
            // if (x.message) {
            //     throw x;
            // }

            //This will only  throw exception message when an other error occurrs.
            if(x.message !== "Failed to fetch" && !x.message){
                throw x;
            }
        });
    }
}